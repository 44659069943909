import TicketSubmitRetryModal from '@src/terminal/app/modals/TicketSubmitRetryModal.vue';
import Modals from '@store/modules/ui/shared/modal/modals';
import SportSystemsModal from '@src/terminal/app/modals/SportSystemsModal.vue';
import NumberInputModal from '@src/terminal/app/modals/NumberInputModal.vue';
import CancelTicketModal from '@src/terminal/app/modals/CancelTicketModal.vue';
import PayoutTicketModal from '@src/terminal/app/modals/PayoutTicketModal.vue';
import DateTimeInputModal from '@src/terminal/app/modals/DateTimeInputModal.vue';
import BoostedOddConflict from '@src/terminal/app/modals/BoostedOddConflict.vue';
import OverlayModal from '@src/terminal/app/modals/OverlayModal.vue';
import ErrorModal from '@app/modals/modals/ErrorModal.vue';
import SuperbonusPromoModal from '@src/terminal/app/modals/SuperBonusPromoModal.vue';

export default {
    [Modals.error.code]: ErrorModal,
    [Modals.ticketSubmitRetry.code]: TicketSubmitRetryModal,
    [Modals.sportSystems.code]: SportSystemsModal,
    [Modals.numberInput.code]: NumberInputModal,
    [Modals.payoutTicket.code]: PayoutTicketModal,
    [Modals.cancelTicket.code]: CancelTicketModal,
    [Modals.dateTimeInput.code]: DateTimeInputModal,
    [Modals.overlayModal.code]: OverlayModal,
    [Modals.boostedOddConflict.code]: BoostedOddConflict,
    [Modals.superbonusPromo.code]: SuperbonusPromoModal,
};
