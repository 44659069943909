import { Dispatch, ActionContext, Commit } from 'vuex';
import {
    isEmpty as _isEmpty,
} from '@lodash';
import { OFFER_PREFIX } from '@app/router/constants';
import Match from '@core/models/offer/Match';
import StoreUtil from '@store/StoreUtil';
import slugify from '@utils/url/slugify';
import { SelectedTreeItem } from './types';
import { PredefinedItems, MarketFilter } from './enums';
import { IState } from '@app/store';
import {
    INode,
} from '@app/utils/sidebar';
import * as RawFunctions from './rawFunctions';
import { PROMOTIONS_ID, PROMOTIONS_TITLE } from '../constants';
import { RelativeRootGetters } from '@store/types';
import { getIntervalForTimeFilter, getTimeFilterFromSlug } from '@src/terminal/app/modules/shared/timeFilter/helpers';

// careful not to conflict with special tournament ids
const ALL_ID = -1000;
const PROMOTIONS_PATH = PROMOTIONS_TITLE;

const SET_SELECTED_TREE_ITEMS = 'SET_SELECTED_TREE_ITEMS';
const SET_SELECTED_FEATURED_ITEM = 'SET_SELECTED_FEATURED_ITEM';
const CLOSE_TIME_FILTER = 'CLOSE_TIME_FILTER';
const SET_MARKET_FILTER = 'SET_MARKET_FILTER';
const SET_IS_LOADING = 'SET_IS_LOADING';

export interface ISportsState {
    selectedTreeItems: SelectedTreeItem[];
    selectedFeaturedItem: number | null;
    selectedMarketFilter: MarketFilter;
    isLoading: boolean;
}

export default {
    namespaced: true,

    state: {
        selectedTreeItems: [],
        selectedFeaturedItem: null,
        selectedMarketFilter: MarketFilter.allMarkets,
        isLoading: false,
    } as ISportsState,

    mutations: {
        [SET_SELECTED_TREE_ITEMS]: (state: ISportsState, selectedItems: SelectedTreeItem[]) => {
            state.selectedFeaturedItem = null;
            state.selectedTreeItems = selectedItems;
        },
        [SET_SELECTED_FEATURED_ITEM]: (state: ISportsState, selectedItem: number) => {
            state.selectedFeaturedItem = selectedItem;
            state.selectedTreeItems = [];
        },
        [SET_MARKET_FILTER]: StoreUtil.createSimpleMutator('selectedMarketFilter'),
        [SET_IS_LOADING]: StoreUtil.createSimpleMutator('isLoading'),
    },

    getters: {
        /**
         * This getter takes treeBreadcrumbsIds and returns SelectedTreeItem object
         * with type (sport, tournament, category) and id so we can.  Events are going
         * to be filtered only by one type, the rightmost in breadcrumbs
         */
        selectedTreeItem: (state: ISportsState, getters: any, rootState: IState, rootGetters: RelativeRootGetters) => {
            return RawFunctions.selectedTreeItem(rootState.ui.sportOffer.sports, rootState, rootGetters);
        },

        /**
         * Maps URL to breadcrumb of ids.
         *
         * This getter takes slugs and maps slugs to ids (from tree for some date, not for struct!).
         * If someone comes from outside with offer/football/anglia method first finds id of football
         * and id of anglia.
         *
         * Example: if path is offer/football/anglia breadcrumb is
         * football > anglia and getter returns [5, 42]
         */
        treeBreadcrumbsIds(state: ISportsState, getters: any, rootState: IState, rootGetters: any) {
            const selectedFeaturedItem = getters.selectedFeaturedItem;
            if (selectedFeaturedItem) {
                return [];
            }
            const breadcrumbIds = getters._selectedFilter;
            return _isEmpty(breadcrumbIds) ? [ALL_ID] : breadcrumbIds;
        },

        _selectedFilter(state: ISportsState, getters: any, rootState: IState, rootGetters: any) {
            return RawFunctions.selectedFilter(state, rootState, rootGetters);
        },

        /**
         * This getter creates and updates sports sidebar tree from events filtered by some date
         * + previously selected breadcrumbs if date doesn't contain items in breadcrumbs or only some items
         */
        tree(state: ISportsState, getters: any, rootState: IState, rootGetters: any) {
            return RawFunctions.tree(rootState.ui.sportOffer.sports, rootState, rootGetters);
        },

        /**
         * Uses date from dateTimeFilter and returns all events with that date
         */
        eventsFilteredByDate(state: ISportsState, getters: any, rootState: IState, rootGetters: any): Match[] {
            return RawFunctions.eventsFilteredByDate(state, rootState, rootGetters);
        },

        /**
         * This getter returns events for rendering, events are filtered by date from dateTimeFilter
         * and all the other filters (sport, competition, etc.)
         */
        filteredEvents(state: ISportsState, getters: any, rootState: IState, rootGetters: any): Match[] {
            return RawFunctions.filteredEvents(state, rootState, rootGetters);
        },

        promotionalEventGroups(state: ISportsState, getters: any, rootState: IState, rootGetters: any) {
            return RawFunctions.filteredPromotionalEvents(state, rootState, rootGetters);
        },

        topTenEvents(state: ISportsState, getters: any, rootState: IState, rootGetters: any) {
            const { type } = getters.selectedTreeItem;
            if (getters.selectedFeaturedItem || (type !== PredefinedItems.all && type !== PredefinedItems.promotions)) {
                return [];
            }

            return RawFunctions.topTenEvents(state, rootState, rootGetters);
        },

        pathName(state: ISportsState, getters: any, rootState: IState, rootGetters: any): string {
            return rootGetters['navigation/route'].path;
        },

        sportsFilter(state: ISportsState, getters: any, rootState: IState, rootGetters: any) {
            return RawFunctions.sportsFilter(state, rootState, rootGetters);
        },

        selectedTreeItems: StoreUtil.createSimpleGetter('selectedTreeItems'),

        selectedFeaturedItem: RawFunctions.selectedFeaturedItem,

        selectedMarketFilter: StoreUtil.createSimpleGetter('selectedMarketFilter'),

        isLoading: StoreUtil.createSimpleGetter('isLoading'),

        timeFilter(state: ISportsState, getters: any, rootState: IState) {
            return RawFunctions.getTimeFilter(rootState);
        }
    },
    actions: {
        /**
         * When someone clicks on item in tree this action sets selectedItem objects
         * and updates router's URL with new path
         *
         * Example: we click on football > anglia this action sets url to offer/football/anglia
         */
        setSelectedTreeItem: (
            { commit, dispatch, rootGetters }:
                { commit: Commit, dispatch: Dispatch, rootGetters: any },
            selectedTreeItems: any[]
        ) => {
            commit(SET_SELECTED_TREE_ITEMS, selectedTreeItems);

            const pathParts = getSelectedTreeItemSlugs(selectedTreeItems);
            const { timeFilter } = rootGetters['navigation/route'].params;

            if (timeFilter) {
                pathParts.push(timeFilter);
            }

            const path = [OFFER_PREFIX, ...pathParts].join('/');
            dispatch('navigation/push', path, { root: true });
        },

        clearSelectedFeaturedItem: ({ commit }: { commit: Commit }) => {
            commit(SET_SELECTED_FEATURED_ITEM, null);
        },
        closeTimeFilter: StoreUtil.createSimpleMutatorAction(CLOSE_TIME_FILTER),

        setMarketFilter: StoreUtil.createSimpleMutatorAction(SET_MARKET_FILTER),

        async loadOffer(context: ActionContext<ISportsState, IState>) {
            const timeFilterSlug = context.rootState.route.params.timeFilter;
            const timeFilter = getTimeFilterFromSlug(timeFilterSlug);
            const timeSpan = getIntervalForTimeFilter(timeFilter);
            context.commit(SET_IS_LOADING, true);
            await context.dispatch('data/sportOffer/fetchEvents', timeSpan, { root: true });
            context.commit(SET_IS_LOADING, false);
        }
    },
};

function getSelectedTreeItemSlugs(selectedTreeItems: any[]) {
    const sport = selectedTreeItems[0];
    if (sport && sport.id === PROMOTIONS_ID) {
        return [slugify(PROMOTIONS_PATH)];
    }

    if (sport && sport.id !== ALL_ID) {
        return selectedTreeItems.map((item: INode) => slugify(item.name));
    }
    return [];
}
