/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
/* tslint:disable max-line-length */
const TRANSLATION_PENDING = '#translationPending';

export default {
    'Betting Unavailable after retry': 'Pariuri indisponibile după reîncercare',
    'SuperBonus': 'SuperBonus',
    'accept changes and bet now': 'acceptă modificări și pariază acum',
    'SuperOdd cannot be combined with other selections. Want to replace {removedSelectionCount} selections with <strong>{newSelectionName} SuperOdd</strong>': 'SuperOdd nu poate fi combinat cu alte selecții. Dorești să {removedSelectionCount} selecții sa <strong>{newSelectionName} SuperOdd</strong>',
    'SuperOdd cannot be combined with other selections. Want to replace <strong>{firstRemovedSelectionName} SuperOdd </strong>with {newSelectionName}': 'SuperOdd nu poate fi combinat cu alte selecții. Dorești să <strong>{firstRemovedSelectionName} SuperOdd </strong>sa {newSelectionName}',
    'Incompatible selections': 'Selecții incompatibile',
    'replace': ' ÎNLOCUIEȘTE',
    'SuperAdvantage': 'SuperAvantaj',
    'win secured with SuperAdvantage! You will receive your payout, and whether you receive the SuperBonus will depend on the final result of the match.': 'Câștig asigurat cu SuperAvantaj! Veți primi plata, iar dacă primiți SuperBonus va depinde de rezultatul final al meciului.' ,
    'congratulations, you’ve won this bet with SuperAdvantage! 🎉 This ticket not eligible for Super Bonus.': 'Felicitări, ați câștigat acest pariu cu SuperAvantaj! 🎉 nu este eligibil pentru Super Bonus.',
    'given that at least one of your events were won through Super Advantage, this ticket is not eligible for Progressive Lucky Loser.': 'Dat fiind că cel puțin unul dintre evenimentele tale a fost câștigat prin SuperAvantaj, acest bilet nu este eligibil pentru Pariu Sansa Progresiv.',
    'congratulations, one or more selections are won with SuperAdvantage! 🎉 Please be aware, selections won with SuperAdvantage do not count towards SuperBonus.': 'Felicitări, una sau mai multe selecții sunt câștigate cu SuperAvantaj! 🎉 Vă rugăm să fiți conștienți, selecțiile câștigate cu SuperAvantaj nu sunt luate în considerare pentru SuperBonus.',
    'auto accept changes': 'acceptă automat modificările',
    'automatic odds changing acceptance': 'Acceptarea automată a modificărilor de cote',
    'some odds modified, old ones cut': 'Unele cote modificate, cele vechi tăiate',
    'ticket print success': 'bilet printat cu succes',
    'no result': 'niciun rezultat',
    'print success': 'printat cu succes',
     Draws: 'extrageri',
    'exit': 'ieșire',
    'repeat ticket': 'repetați biletul',
    'Cumulative stake': 'Miza cumulativă',
    'Cumulative stake after tax': 'Miza cumulată după impozitare',
    'ticketNotEligibleForLuckyLoser' : 'Biletul tău <span>nu este eligibil</span> pentru <span>Pariu Sansa Progresiv</span>, dacă ai selecții <span>live</span>, selecții cu o cotă sub <span>1.30</span> sau selecții din categoriile <span>Super Cota/Super Extra</span>.',
    'Progressive Lucky Loser is ACTIVE': '<span>Pariul Sansa Progressive</span> este <span>ACTIV</span>',
    'The minimum stake per combination is {minimumStakePerCombination}. Your current value is {stakePerCombination}': 'Miza minimă pe combinație este {minimumStakePerCombination}. Valoarea dvs. actuală este {stakePerCombination}',
    'to cash-out this ticket, please go over the counter': 'Pentru a încasa acest bilet, vă rugăm să mergeți la casierie',
    'ticket paid out successfully': 'bilet plătit cu succes',
    'please scan the customer card': 'vă rugăm să scanați cardul de client',
    'payout ticket': 'Plată bilet',
    'invalid customer card number': 'număr card client nevalid',
    'cancel ticket': 'Anulați biletul',
    'ticket canceled successfully': 'Biletul este anulat',
    'ticket already canceled': 'Biletul a fost deja anulat',
    'wrong control code': 'Codul de control {controlCode} nu este valid pentru biletul {ticketId}.',
    'input ticket control code': 'Tastează codul de control al biletului',
    yes: 'da',
    'do not navigate away from this screen until the ticket is printed': 'Nu părăsiți pagina înainte ca biletul să fie printat',
    'ticket reprint': 'Reprintare bilet',
    // check ticket translations

    // ticketId: 'ID bilet',
    // stake: 'Miză',
    winnings: 'Winnings',
    // payout: 'Plată bilet',
    // status: 'Status',
    // type: 'Type',
    bet: 'Bet',
    payin: 'Total',
    handlingFee: 'CDA 5%',
    payoutTax: 'Payment tax',
    // code: 'Cod',
    // fix: 'Fix',
    event: 'Eveniment',
    // date: 'Data',
    margin: '-/+',
    market: 'Pariu',
    // coefficient: 'Cotă',
    ticketStatus: 'Starea Biletelor',
    potentialPayoff: 'Câștig Potențial',
    actualPayoff: 'Câștig total',
    minPotential: 'Câștig potențial minim',
    // bonus: 'Primă',
    // currency: 'RON',
    repeatTicketMsg: 'Poți copia evenimentele disponibile de pe bilet cu ajutorul butonului Repeta Bilet de mai jos.',
    cancelTicketMsg: 'Cel puțin un eveniment de pe bilet a început, astfel că anularea biletului nu mai este disponibilă.',
    inProgress: 'În desfăşurare',
    // active: 'Activ',
    // lost: 'Necâştigător',
    // win: 'Câştigător',
    // refund: 'Rambursat',
    // canceled: 'Stornat',
    // sports: 'sport',
    // check ticket translations end
    'Application initializing': 'Aplicația pornește',
    'Maintenance in progress': 'Mentenanță în desfășurare',
    'Ticket printed successfully': 'Biletul tipărit cu succes',
    'lucky loser candidate': 'Pariul Şansă',
    'Negotiating...': 'Pariul este în curs de autorizare și poate dura până la câteva minute.',
    // terminal specific translations start
    insufficientFounds: 'Fonduri insuficiente',
    enterNumber: 'Introduceți suma',
    chooseSystems: 'SISTEME',
    // terminal specific translations end
    // printed ticket translations
    ticketId: 'Serie bilet',
    ticketCode: 'Cod',
    copy: 'Copie',
    ticketType: 'Tip Bilet',
    eventsCount: 'Evenimente',
    totalStake: 'Total',
    cda: 'CDA',
    cleanStake: 'Miză',
    totalCoefficient: 'Cotă totală',
    numberCombinations: 'Nr.Comb',
    minPotentialWin: 'Câștig Min',
    potentialWin: 'Câștig potențial',
    luckyLoser: 'Pariul Şansă Progresiv',
    simpleTicket: 'NORMAL',
    systemTicket: 'SISTEM',
    extraWinTax: 'Extra tax',
    eventFix: 'FIX',
    fixLabel: 'F',
    'print ticket': 'tipărește biletul',
    // printed ticket translations end
    stake: 'Miză',
    currencySymbol: 'R',
    currencyAbbreviation: 'RON',
    totalScore: 'T',
    currentPeriodScore: 'C',
    previousPeriodScore: 'A',
    G: 'G',
    S: 'S',
    M: 'M',
    '13 digit number': 'număr din 13 cifre',
    '€ 0.20 + VAT per minute': '€ 0.20 + TVA pe minut',
    england: 'anglia',
    'england amateur': 'amatori anglia',
    iceland: 'islanda',
    ghana: 'ghana',
    'faroe islands': 'insulele feroe',
    estonia: 'estonia',
    'denmark amateur': 'amatori danemarca',
    china: 'china',
    canada: 'canada',
    portugal: 'portugalia',
    cameroon: 'camerun',
    'bosnia & herzegovina': 'bosnia & herțegovina',
    belarus: 'belarus',
    'austria amateur': 'amatori austria',
    armenia: 'armenia',
    albania: 'albania',
    malta: 'malta',
    'germany amateur': 'amatori germania',
    cyprus: 'cipru',
    greece: 'grecia',
    germany: 'germania',
    'international youth': 'internaționale de tineret',
    'united arab emirates': 'emiratele arabe unite',
    israel: 'israel',
    kenya: 'kenya',
    philippines: 'filipine',
    italy: 'italia',
    scotland: 'scoția',
    'saudi arabia': 'arabia saudită',
    panama: 'panama',
    spain: 'spania',
    egipt: 'egipt',
    jamaica: 'jamaica',
    'south africa': 'africa de sud',
    finland: 'finlanda',
    france: 'franța',
    qatar: 'qatar',
    iran: 'iran',
    australia: 'australia',
    ecuador: 'ecuador',
    brazil: 'brazilia',
    chile: 'chile',
    venezuela: 'venezuela',
    mexico: 'mexic',
    vietnam: 'vietnam',
    azerbaijan: 'azerbaidjan',
    'costa rica': 'costa rica',
    honduras: 'honduras',
    'hong kong': 'hong kong',
    romania: 'românia',
    pakistan: 'pakistan',
    guatemala: 'guatemala',
    international: 'internațional',
    turkey: 'turcia',
    bolivia: 'bolivia',
    senegal: 'senegal',
    netherlands: 'olanda',
    'new zealand': 'noua zeelandă',
    tunisia: 'tunisia',
    wales: 'țara galilor',
    'czech republic': 'cehia',
    'northern ireland': 'irlanda de nord',
    belgium: 'belgia',
    croatia: 'croația',
    'turkey amateur': 'amatori turcia',
    'el salvador': 'el salvador',
    colombia: 'columbia',
    austria: 'austria',
    'san marino': 'san marino',
    argentina: 'argentina',
    switzerland: 'elveția',
    macedonia: 'macedonia',
    poland: 'polonia',
    sweden: 'suedia',
    norway: 'norvegia',
    bulgaria: 'bulgaria',
    hungary: 'ungaria',
    slovenia: 'slovenia',
    russia: 'rusia',
    slovakia: 'slovacia',
    ukraine: 'ucraina',
    serbia: 'serbia',
    denmark: 'danemarca',
    bahrain: 'bahrain',
    india: 'india',
    indonesia: 'indonezia',
    latvia: 'letonia',
    gibraltar: 'gibraltar',
    'ivory coast': 'coasta de fildeș',
    kuwait: 'kuweit',
    andorra: 'andorra',
    paraguay: 'paraguay',
    algeria: 'algeria',
    jordan: 'iordania',
    malaysia: 'malaezia',
    singapore: 'singapore',
    ireland: 'irlanda',
    georgia: 'georgia',
    uzbekistan: 'uzbekistan',
    usa: 'sua',
    uruguay: 'uruguay',
    thailand: 'thailanda',
    'sweden amateur': 'amatori suedia',
    'south korea': 'coreea de sud',
    peru: 'peru',
    oman: 'oman',
    morocco: 'maroc',
    montenegro: 'muntenegru',
    moldova: 'moldova',
    luxembourg: 'luxemburg',
    lithuania: 'lituania',
    liechtenstein: 'liechtenstein',
    kazakhstan: 'kazahstan',
    japan: 'japonia',
    uganda: 'uganda',
    yemen: 'yemen',
    rwanda: 'rwanda',
    burundi: 'burundi',
    'about us': 'despre noi',
    'about-us': 'despre-noi',
    'About Superbet': 'Despre Superbet',
    accept: 'accept',
    account: 'cont',
    active: 'activ',
    'and is available from Vodafone, Orange and Telekom networks. We will take all calls in the order they were made.': 'și este disponibil în rețelele Vodafone, Orange și Telekom. Apelurile vor fi preluate în ordinea efectuării.',
    address: 'adresă',
    'agencies need': 'agenții',
    'agree to terms and conditions': 'acceptă termenii și condițiile',
    'age warning': 'Accesul persoanelor sub 18 ani este strict interzis.',
    all: 'toate',
    'all types': 'toate tipurile',
    'all sports': 'toate sporturile',
    'all tournaments': 'toate campionatele',
    'all markets': 'toate tipurile de pariuri',
    'all offer': 'toată oferta',
    alerts: 'alerte',
    amount: 'suma',
    agency: 'agenție',
    'agency list': 'lista de agenții',
    agencies: 'agenții',
    articles: 'articole',
    'agencies-content': 'agenții',
    'all lottery': 'toate loteriile',
    back: 'înapoi',
    'bank transfer deposits': 'depuneri transfer bancar',
    betSlip: 'bilet',
    'Betslip is empty. Select events from offer and place your bet.': 'Biletul este gol. Alege un eveniment din ofertă pentru a plasa un pariu.',
    bets: 'pariuri',
    bonus: 'bonus',
    'bonus-bun-venit-sport': 'bonus-bun-venit-sport',
    'bonus-bun-venit-casino': 'bonus-bun-venit-casino',
    'bonus terms & conditions': 'termeni și condiții bonus',
    besix: 'besix',
    'besix results': 'rezultate besix',
    'browse files': 'alege fișier',
    'bets info': 'info pariuri',
    'bookie changed the bet, do you accept?': 'pariul a fost modificat de bookie, accepți?',
    cancel: 'anulare',
    canceled: 'anulat',
    'canceling the transaction failed': 'nu s-a reușit anularea tranzacției',
    casino: 'casino',
    'Casino is currently under maintenance': 'Secțiunea Casino este momentan în mentenanță',
    'casino live': 'casino live',
    'Casino Live - Blackjack, Roulette': 'Casino Live - Blackjack, Roulette',
    cash: 'cash',
    'casino plays': 'pariuri casino',
    careers: 'cariere',
    'check your events, you have errors': 'verifică evenimentele, au apărut erori',
    'Changed from {oldValue} to {newValue}': 'S-a modificat de la {oldValue} la {newValue}\n',
    'check ticket': 'verificare bilet',
    'change password': 'schimbare parolă',
    'choose bonus now and deposit': 'alege bonusul acum și efectuează o depunere',
    'choose bonus': 'alege bonus',
    'change URL sent to your E-mail': 'Te rugăm să urmezi instrucțiunile trimise pe adresa de email',
    'close the preview': 'închide previzualizarea',
    city: 'localitate',
    cities: 'localități',
    'Choose your bonus before deposit': 'Alege bonusul pe care îl dorești înainte de a depune',
    close: 'închide',
    'Close All': 'Închide tot',
    combinations: 'combinații',
    CNP: 'CNP',
    confirm: 'confirmare',
    collapse: 'extinde',
    country: 'țară',
    combination: 'combinație',
    competitors: 'concurenți',
    'choose sport and tournament first': 'alege sportul și campionatul întâi',
    'combinations count': 'număr de combinații',
    'choose date': 'alege data',
    code: 'cod',
    continue: 'continuă',
    coefficient: 'coeficient',
    county: 'județ',
    create: 'creare',
    currency: 'monedă',
    'currently under maintenance': 'momentan în mentenanță',
    'cup roster': 'participanți',
    date: 'data',
    'data protection': 'protecția datelor',
    'daily offer': 'oferta zilnică',
    decline: 'refuză',
    demo: 'demo',
    deposit: 'depunere',
    details: 'detalii',
    'deposit and withdraw through agencies, no commission!': 'Depune și retrage prin agenții!',
    'deposit and withdraw through agencies, no commission': 'Depune și retrage prin agenții!',
    'details on': 'detalii despre',
    'details about agency deposits': 'detalii privind depunerile prin agenții',
    'details of bank transfer deposits': 'detalii privind depunerile prin transfer bancar',
    'deposit request': 'solicitare depunere',
    'deposit response': 'răspuns solicitare',
    'details of your Romainian residence for regulatory purposes': 'detaliile reședinței în România, conform reglementărilor',
    disclaimer: 'Este responsabilitatea fiecărui jucător să acționeze în conformitate cu reglementările în vigoare precum și a termenilor și condițiilor noastre. Jocurile de noroc implică risc financiar, jucați cu grijă.',
    'dog race results': 'rezultate curse câini',
    dogs: 'câini',
    'Do you want to move to the next round?': 'Dorești să mergi la următoarea rundă?',
    'confidence-declaration': 'declaratia-de-confidentialitate',
    'declaratia-de-confidentialitate': 'declaratia-de-confidentialitate',
    edit: 'editează',
    'enter the amount': 'introdu suma',
    'enter home address': 'completează adresa',
    'Enter the code and see if you won': 'Completează codul și află dacă ai câștigat',
    'E-mail': 'E-mail',
    'E-mail not found': 'Adresa de E-mail nu a fost găsită',
    'event name': 'nume eveniment',
    events: 'evenimente',
    'event/odd is blocked': 'evenimentul/cotă este blocat momentan',
    'event has finished': 'evenimentul s-a încheiat',
    expand: 'extinde',
    explore: 'explorează',
    error: 'eroare',
    errors: 'erori',
    FAQ: 'Întrebări frecvente',
    for: 'pentru',
    'fetching data': 'se pregătesc datele',
    finished: 'finalizat',
    filter: 'fitrare',
    'first name': 'prenume',
    fiveMostRare: 'Cele mai rare 5 numere',
    fiveMostFrequent: 'Cele mai frecvente 5 numere',
    'file successfully sent': 'fișierul a fost trimis cu succes',
    fix: 'fix',
    fixed: 'fix',
    'Feel free to make your ticket and explore the site. You will be required to fill in all the missing information and deposit it with your first bet ': 'Poți să creezi bilete și să explorezi site-ul. Va trebui să completezi datele care lipsesc și să efectuezi o depunere la plasarea primului pariu ',
    'find the nearest shop': 'găsește cea mai apropiată agenție',
    'forgot password': 'ai uitat parola',
    friday: 'vineri',
    help: 'ajutor',
    'Important: The number of WhatsApp is exclusive to chat. The Superbet operator will respond to you in writing.': 'Important: numărul de WhatsApp este exclusiv pentru chat. Echipa de suport îți va răspunde în scris.',
    'help!': 'ajutor!',
    'help-url': 'ajutor',
    'hide password': 'ascunde parola',
    'highest winning': 'cel mai mare câștig',
    hours: 'ore',
    hour: 'ore',
    H2H: 'rezultate directe',
    good: 'bun',
    'greyhound races': 'curse câini',
    'greyhound retail': 'curse câini agenții',
    'greyhound virtual': 'curse virtuale câini ',
    'gaming-rules': 'regulamente-de-joc',
    genericTicketSubmitError: 'Nu s-a putut genera biletul. Te rugăm să încerci din nou sau să contactezi echipa de suport.',
    gamesUnavailableError: 'Acest joc este momentan indisponibil! Nu se poate juca sau adăuga la favorite. Te rugăm să încerci mai târziu.',
    'I accept automatic quota update': 'Acceptare automată a schimbărilor de cote',
    "I'll do that later": 'mai târziu',
    info: 'info',
    'invalid username or password': 'Nu ai introdus corect utilizatorul sau parola',
    'in progress': 'în procesare',
    'it is not possible to combine same market': 'nu se pot combina aceleași tipuri de pariuri',
    'It is not possible to combine this event with events you already have on your betslip': 'nu se poate combina cu celelalte evenimente pe care le ai deja pe bilet',
    'It is not possible to combine this market with markets you already have on your betslip': 'nu se poate combina cu tipurile de pariuri pe care le ai deja pe bilet',
    'It is not possible to combine this with events you already have on your betslip': 'nu se poate combina cu evenimentele pe care le ai deja pe bilet',
    'I agree with the use of my personal data by Superbet through the means of communication provided to receive newsletters about general offers related to relevant products, campaigns or services.': 'Sunt de acord cu utilizarea datelor mele personale de către Superbet, prin mijloacele de comunicare puse la dispoziție, pentru a primi buletine de informare despre oferte generale legate de produse, campanii sau servicii relevante.',
    'I agree with the use of my personal data by Superbet to receive, through the means of communication made available based on my player profile analysis and opinion polls in this regard, personalized offers about promotions and / or bonuses.': 'Sunt de acord cu utilizarea datelor mele personale de către Superbet pentru a primi, prin mijloacele de comunicare puse la dispoziție, în baza analizei profilului meu de jucător și a unor sondaje de opinie în acest sens, oferte personalizate despre promoții și/sau bonusuri.',
    'I am 18 years old. I have read, understand and agree to the': 'Declar pe propria răspundere că am 18 ani împliniți și am citit, am înțeles, sunt de acord cu',
    jackpot: 'jackpot',
    'last name': 'nume',
    'last winner': 'ultimul câțtigător',
    'Latest news in world of sport': 'Ultimele noutăți din lumea sportului',
    'live bet': 'pariu live',
    like: 'like',
    'live betting': 'pariuri live',
    live: 'live',
    lost: 'pierdut',
    'live results': 'rezultate live',
    'Live betting is currently under maintenance': 'Pariurile live sunt momentan în mentenanță',
    lottery: 'loto',
    'lottery results': 'rezultate loto',
    login: 'intră în cont',
    loading: 'se încarcă',
    'Lottery betting - Greece Kino, Win for Life, Numerone': 'Pariuri Loto - Grecia Kino, Win for Life, Numerone',
    'lucky six': 'lucky six',
    'lucky x': 'lucky x',
    'luckysix retail': 'lucky six agenții',
    lineups: 'formații',
    'Live Chat': 'Live Chat',
    'live events are not allowed on offline betSlip': 'nu poți juca evenimente live pe un bilet de agenție ',
    lotteries: 'loterii',
    'main market not available, click to': 'pariurile principale nu sunt disponibile, apasă pentru',
    'see more markets': 'a vedea mai multe tipuri de pariuri',
    'maximum number of rest events reached': 'ai atins numărul maxim de evenimente',
    'Watch match on TV': 'Vezi meciul la TV',
    'want to see all the offers': 'vrei să vezi toate ofertele',
    mastercard: 'mastercard',
    matches: 'meciuri',
    markets: 'tipuri de pariuri',
    'markets temporary locked': 'pariurile sunt momentan blocate',
    maintenance: 'mentenanță',
    message: 'mesaj',
    'max {count} numbers': 'maxim {count} numere',
    'maximum number of selections reached': 'ai atins numărul maxim de selecții',
    'maximum length is {value}': 'lungimea maximă este {value}',
    'maximum is {value}': 'maxim {value}',
    'match details': 'detalii meci',
    'minimum potential win': 'câștig potențial minim',
    'minimum is {value}': 'minim {value}',
    'minimum stake on ticket raised': 's-a mărit miza minimă pe bilet',
    'minimum system to select is {minNumber}/{fromNumber}': 'sistemul minim pe care îl poți selecta este {minNumber}/{fromNumber}',
    'minimum length is {value}': 'numărul minim de caractere este {value}',
    'Minimum stake is {minimumStake}': 'Miza minimă este {minimumStake}',
    'min potential win': 'câștig potențial min',
    'Maximum stake is {maximumStake}': 'Miza maximă este {maximumStake}',
    'my bets': 'pariurile mele',
    monday: 'luni',
    modifications: 'modificări',
    'my numbers': 'numerele mele',
    'my picks': 'alegerile mele',
    'max 16 numbers': 'maxim 16 numere',
    'maximal win amount of {maxWinAmount} reached': 'ai atins câștigul maxim posibil de {maxWinAmount}',
    'potential winning amount lower than the stake': 'Câștigul maxim posibil este mai mic decât miza',
    'win amount too small, minimal is {minWinAmount}': 'valoarea câștigului este prea mică, minimul este {minWinAmount}',
    'N/A': 'Nedisponibil',
    name: 'nume',
    news: 'știri',
    'new potential win': 'câștig potențial nou',
    neteller: 'neteller',
    'no available markets': 'niciun pariu disponibil',
    'no data': 'nu există date disponibile',
    'no events for this team name exist in this time period': 'nu există evenimente ale acestei echipe pentru perioada aleasă',
    'no results found for': 'niciun rezultat găsit pentru',
    'not found': 'nu s-a găsit',
    '404 - page not found': 'Hmm, se pare că pagina nu există...',
    'but you’re still a winner!': 'dar tot ești un câștigător!',
    'and your prize: an amazing sport fact you probably didn’t know.': 'și premiul tău: un detaliu surprinzător din sport pe care probabil nu îl știai.',
    'next six': 'next six',
    'no markets currently available': 'niciun pariu disponibil momentan',
    'not valid E-mail': 'adresa de email nu este validă',
    'nothing to retract.': 'nimic de retras.',
    new: 'nou',
    next: 'următor',
    'next round': 'runda următoare',
    'NON-STOP': 'NON-STOP',
    number: 'număr',
    'number randomizer': 'număr aleatoriu',
    numbers: 'numere',
    'number of winners': 'număr de câștigători',
    'number of combinations': 'număr de combinații',
    'no events on ticket': 'niciun eveniment pe bilet',
    'no favourite numbers saved for this game yet': 'nu ai salvat încă numere favorite pentru acest joc',
    'odds tolerance': 'toleranță cote',
    odds: 'cote',
    offer: 'ofertă',
    offline: 'agenții',
    'offline ticket': 'bilet agenții',
    ok: 'ok',
    online: 'online',
    or: 'sau',
    other: 'altele',
    payout: 'plată',
    payoff: 'plată',
    password: 'parolă',
    'page not authorized': 'te rugăm să te loghezi',
    'page not found': 'ups, pagina căutată nu s-a găsit',
    'password strength': 'securitate parolă',
    payment: 'plată',
    'Part of the offer is unavailable during this maintenance': 'O parte din ofertă este indisponibilă în timpul lucrărilor de mentenanță',
    'payment reference': 'Referință plată',
    pending: 'în așteptare',
    phone: 'număr de telefon',
    play: 'joacă',
    'Please do not call us at this number.': 'Te rugăm să nu efectuezi apeluri la acest număr.',
    'play now': 'joacă acum',
    'played combinations': 'combinații jucate',
    'please contact our support': 'te rugăm să contactezi echipa de suport',
    'pick county': 'alege un județ',
    'pick your city': 'alege localitatea',
    place: 'plasează',
    'please refresh your page': 'te rugăm să reîncarci pagina',
    'please select at least one combination': 'te rugăm să selectezi cel puțin o combinație',
    'Placing bet...': 'Se plasează pariul...',
    'Recreating ticket...': 'Bilet de recreație...',
    'potential win': 'câștig potențial',
    'potential bonus percentage': 'procent SuperBonus potențial',
    'potential bonus value': 'valoare SuperBonus potențial',
    'potential bonus': 'SuperBonus potențial',
    popular: 'populare',
    print: 'printare',
    profile: 'profil',
    previous: 'anterior',
    'bet now': 'pariază acum',
    'some odds have changed, press bet now to accept': 'Pariul nu a fost plasat deoarece unele cote au fost modificate (cele vechi apar tăiate). Apăsаți din nou pe butonul verde "Pariază acum"',
    'put more events of same market': 'adaugă mai multe evenimente de acest tip',
    'put more events of other markets': 'adaugă mai multe evenimente de alt tip',
    'payment-method-bank-transfer': 'metode-de-plata-transfer-bancar',
    'metode-de-plata-transfer-bancar': 'metode-de-plata-transfer-bancar',
    'payment-method': 'metode-de-plata',
    'payment-method-agency': 'metode-de-plata',
    'previuos match result': 'rezultat meci anterior',
    'quick choice': 'alegere rapidă',
    real: 'real',
    recommended: 'recomandate',
    register: 'înregistrare',
    'remove all': 'șterge tot',
    reset: 'resetare',
    read: 'citește',
    refund: 'rambursare',
    'responsible gaming': 'joc responsabil',
    'results for': 'rezultate pentru',
    results: 'rezultate',
    'Results are currently under maintenance': 'Secțiunea Rezultate este momentan în mentenanță',
    result: 'rezultat',
    recreate: 'recreare',
    retry: 'încearcă din nou',
    'Rules of the game. And of course the way we protect and proccess your data': 'Regulile jocului și modul în care îți protejăm și procesăm datele.',
    'responsible-gaming': 'joc-responsabil',
    'rounds with appearance': 'număr de apariții',
    'rounds without appearance': 'runde fără apariție',
    'random numbers': 'aleatoriu',
    saturday: 'sâmbătă',
    'save favourites': 'salvează la favorite',
    save: 'salvează',
    search: 'căutare',
    selected: 'selectate',
    "selected odd doesn't exist anymore": 'cotele selectate nu mai sunt disponibile',
    'selected odd is unavailable': 'cotă selectată nu mai este disponibilă',
    send: 'trimite',
    'session duration': 'durată sesiune',
    'search events': 'căutare evenimente',
    'shop deposits': 'depuneri agenție',
    simple: 'simplu',
    'single ticket': 'bilet simplu',
    'show password': 'arată parola',
    skrill: 'skrill',
    slots: 'sloturi',
    'show more': 'arată mai multe',
    'Show All': 'Arată tot',
    'long info': 'Superbet.ro este deținut și operat de Superbet Interactive (Romania) Limited, C.I.F.: 36446625, Număr de Înregistrare C75273; Level G, (Office 1/5597), Quantum House, 75 Abate Rigord Street, Ta’Xbiex XBX 1120, Malta. Drept de organizare în baza licenței L1160659W000328 cu valabilitate 01.09.2016-31.08.2026 și în baza autorizației de exploatare obținută prin decizia 1800/29.07.2019',
    'Something went wrong. Please try again': 'Ups, ceva nu a funcționat corespunzător. Te rugăm să încerci din nou',
    'Sorry, the time to play this round has expired.': 'Se pare că a expirat timpul în care se poate juca această rundă',
    'sport bets': 'pariuri sportive',
    'virtual bets': 'pariuri virtuale',
    sports: 'sport',
    sport: 'sport',
    'Sports and live betting': 'Pariuri sportive și live',
    // stake: 'miză',
    'stake after tax': 'miza după aplicarea taxei',
    status: 'status',
    string: 'string',
    'sport results': 'rezultate sport',
    'statistical combinations': 'combinații statistice',
    sunday: 'duminică',
    success: 'succes',
    submit: 'trimite',
    'super offer': 'superoferta',
    'submit failed': 'nu s-a reușit trimiterea',
    'successful password change': 'parolă schimbată cu succes',
    'super kvota': 'super cotă',
    'super extra': 'super extra',
    'Super Extra must be combined with at least {minNonPromotionalSelection} other events with a minimum odd {minNonPromotionalOdd}': 'Super Extra se poate combina cu minim {minNonPromotionalSelection} evenimente cu cotă de cel puțin {minNonPromotionalOdd}',
    system: 'sistem',
    systems: 'sisteme',
    'show ball statistics': 'arată statistici bile',
    'Superbet terms and conditions': 'Termenii și condițiile Superbet',
    'this is the entire offer for this competition': 'aceasta este întreaga ofertă pentru competiția selectată',
    'this is the entire offer for': 'aceasta este întreaga ofertă pentru',
    ticket: 'bilet',
    tickets: 'bilete',
    'ticket not found': 'biletul nu a fost găsit',
    teams: 'echipe',
    tax: 'taxă CDA',
    table: 'jocuri de masă',
    'table games': 'jocuri de masă',
    tables: 'clasamente',
    'Terms and Conditions': 'Termeni și Condiții',
    'There are currently no bets available': 'Momentan nu există pariuri disponibile',
    'These are all news related to events and competitions': 'Aici găsești noutăți despre cele mai importante evenimente sportive',
    threeMostFrequent: 'trio frecvent',
    'The phone number has a single rate of': 'Numărul de telefon are un tarif unic de',
    'the next round begins in': 'următoarea rundă începe în',
    'try again': 'încearcă din nou',
    'this field is required': 'te rugăm să completezi acest câmp',
    'this field must be a number': 'te rugăm să completezi un număr în acest câmp',
    'this field must be larger than {value}': 'te rugăm să completezi o valoare mai mare decât {value}',
    'this field must be smaller than {value}': 'te rugăm să completezi o valoare mai mică decât {value}',
    'this is the entire offer for this competition.': 'aceasta este întreaga ofertă pentru competiția selectată',
    threeMostConsecutive: 'Trio frecvent consecutiv',
    thursday: 'joi',
    'This event requires that you add {minNonPromotionalSelection} more events with odd even or greater than {minNonPromotionalOdd}': 'Trebuie să mai adaugi încă {minNonPromotionalSelection} evenimente cu o cotă de cel puțin {minNonPromotionalOdd} ',
    'The registration form was sent successfully. Check your e-mail and complete the registration process. Thank you': 'Felicitări! Contul tău Superbet a fost creat cu succes! Pentru a-l activa, te rugăm să verifici adresa de email folosită la înregistrare și să urmezi instrucțiunile pe care ți le-am trimis.Te rugăm să verifici și în folderul spam / junk.',
    time: 'ora',
    type: 'tip',
    types: 'tipuri',
    'There was some problems, please contact our support': 'A apărut o eroare, te rugăm să contactezi echipa de suport',
    title: 'titlu',
    today: 'astăzi',
    tomorrow: 'mâine',
    'total quota': 'cotă totală',
    'top ten': 'top 10',
    tournaments: 'campionate',
    'tournament details': 'detalii campionat',
    transactions: 'tranzacții',
    tuesday: 'marți',
    'turnover till cashout': 'balanță până la cashout',
    twoMostFrequent: 'Pereche frecventă',
    twoMostConsecutive: 'Pereche frecventă consecutivă',
    'terms-and-conditions': 'termeni-si-conditii',
    'time left': 'timp rămas',
    undo: 'undo',
    unlike: 'unlike',
    upcoming: 'următoare',
    username: 'utilizator',
    'very strong': 'foarte sigură',
    verify: 'verificare',
    'Verify Ticket': 'Verificare Bilet',
    virtual: 'virtuale',
    'virtual results': 'rezultate virtuale',
    'virtual shops': 'virtuale agenții',
    'virtual shops results': 'rezultate virtuale agenții',
    'virtual greyhound races': 'curse virtuale câini',
    'virtual football league': 'fotbal virtual',
    'virtual tennis open': 'tenis virtual',
    'virtual motorcycle speedway': 'curse moto virtuale',
    'virtual horse races': 'curse virtuale cai',
    'Virtual betting - Lucky six, Dog racing, Virtual sports': 'Pariuri virtuale - Lucky six, Curse câini, sporturi virtuale',
    'Virtual games are currently under maintenance': 'Secțiunea Virtuale este momentan în mentenanță',
    'view the privacy statement': 'vezi Politica de Confidențialitate',
    visa: 'visa',
    win: 'câștig',
    won: 'câștigat',
    'Want to see all the offers?': 'Vrei să vezi toate ofertele?',
    wednesday: 'miercuri',
    weak: 'nesigură',
    'weekly offer': 'oferta săptămânală',
    welcome: 'bun-venit',
    'welcome bonus': 'bonus de bun-venit',
    'winning numbers': 'numerele câștigătoare',
    'winning numbers (in their drawing order)': 'numerele câștigătoare (în ordinea extragerii)',
    'wrong code': 'codul nu este corect',
    'wrong current password': 'parolă curentă greșită',
    'registration.withdrawConsent': 'Poți oricând să îți retragi acordul cu privire la procesarea datelor personale accesând contul de client. Retragerea acordului nu va afecta legalitatea procesării datelor efectuată până în momentul retragerii acordului.',
    'your stake': 'miza ta',
    'your password': 'parola ta',
    'you must agree before submitting': 'te rugăm să accepți pentru a continua',
    'You can play exactly 6 matches on the ticket. No system / double paid tickets can be played.': 'Poți juca exact 6 meciuri pe bilet. Nu se poate juca alt sistem / combinație.',
    'You need to deposit to claim this bonus. Do not worry, you will be able to do this later': 'Trebuie să efectuezi o depunere pentru a primi acest bonus. Nicio grijă, vei putea face asta oricând.',
    'You will get a chance to choose your first deposit bonus. We have some extras to do this': 'Vei avea ocazia să îți alegi bonusul pentru prima depunere.',
    'your stake is to small': 'miza este prea mică',
    'You are not allowed to add more than {maxSelectionForMarket} events from this type of market': 'Nu poți adăuga mai mult de {maxSelectionForMarket} evenimente de acest tip',
    'You are not allowed to add more than one event from this type of market': 'Nu poți adăuga mai mult de un eveniment de acest tip',
    'you must play exactly 6 events': 'trebuie jucate exact 6 evenimente',
    'Your session has expired': 'Sesiunea a expirat',
    'you must accept/decline ticket negotiation': 'trebuie să accepți/refuzi biletul negociat',
    'your stake is too big, maximal is {maximum}': 'miza ta este prea mare, poți pune maxim {maximum}',
    'your ticket is in preview mode': 'biletul este în modul previzualizare',
    'you do not have access to this page': 'nu poți accesa această pagină',
    'Your basic info so we know who you are': 'Informații de bază',
    'Your ticket does not exist, check if ID is correct': 'Acest bilet nu există, te rugăm să verifici dacă ai introdus codul corect',
    'Your bet will be placed even if odds change in the meantime!': 'Biletul tău va fi plasat chiar dacă se vor schimba cotele între timp!',
    'quick deposit': 'depunere rapidă',
    'quota changed': 'cotele s-au modificat',
    'length should be {value}': 'lungimea trebuie să fie de {value}',
    'Anti-laundering money policy': 'Politica împotriva spălării banilor',
    'GDPR consent': 'Consimţământ GDPR',
    'Superbet Confidentiality Statement': 'Declarația de Confidențialitate Superbet',
    'registration.intro': 'Pentru a te înregistra pe Superbet, trebuie să citești, să înțelegi și să accepți {terms} precum și să citești, să înțelegi {statement}',
    'registration.terms': 'Declar pe propria răspundere că am 18 ani împliniți și am citit, am înțeles, sunt de acord cu {terms}, și am citit, am înțeles {statement}',
    'you can not place bets for this event anymore': 'nu se mai pot plasa pariuri pe acest eveniment',
    'consimtamant-gdpr': 'consimtamant-gdpr',
    'politica-impotriva-spalarii-banilor': 'politica-impotriva-spalarii-banilor',
    'metode-de-plata#agentii': 'metode-de-plata#agentii',
    'metode-de-plata-online#visa-master': 'metode-de-plata-online#visa-master',
    'metode-de-plata-online#skrill': 'metode-de-plata-online#skrill',
    'metode-de-plata-online#neteller': 'metode-de-plata-online#neteller',
    'metode-de-plata-online#paysafecard': 'metode-de-plata-online#paysafecard',
    'joc-responsabil#18+': 'joc-responsabil#18+',
    'promotii-bonusuri': 'promotii-bonusuri',
    'bonuses-promotions': 'promotii-bonusuri',
    'betting promotions': 'promoții pariuri',
    'You have been online for {value} minutes': 'Autentificat de {value} minute',
    'prepare bet': 'pregătire bilet',
    payed: 'achitat',
    'agency locations': 'listă agenții',
    'back to offer': 'înapoi la ofertă',
    'this page is not publicly avaliable': 'ups, această pagina nu este disponibilă public',
    'to access it please login first': 'pentru a o accesa, te rugăm să te loghezi',
    'authorisation required': 'este necesară autorizarea',
    'did you know': 'știai că...',
    'that ”Federer” can be typed entirely with the left hand': '”Federer” se poate tasta doar cu mâna stângă',
    'roulette retail': 'ruletă virtuală',
    'selected numbers': 'numere selectate',
    'Next round unavailable.': 'Runda următoare nu este disponibilă',
    'Print tickets': 'Printează bilete',
    'Clear betslip': 'NU - Păstrează extragerile',
    'You are about to change the lottery you are currently betting on, and the entire bet-slip content will be cleared. Do you wish to proceed? ': 'Ești pe cale să schimbi loteria pe care pariezi, iar toate evenimentele de pe bilet vor fi șterse. Dorești să continui?',
    'Maximum number of favorites reached.': 'Ai atins numărul maxim de favorite',
    warning: 'atenție',
    'all news': 'toate știrile',
    'bonuses promotions': 'promoții',
    'ticket ID': 'cod bilet',
    '{char} is forbidden': 'carcterul {char} nu este permis',
    quote: 'cotă',
    'Empty space is forbidden': 'Te rugăm să completezi acest câmp',
    'you have fields errors, please check the form': 'Te rugăm să verifici formularul, ai câteva erori',
    'date of birth': 'data naşterii',
    'game currently unavailable': 'jocul este momentan indisponibil',
    "You don't have any favorite games yet.": 'Nu ai adăugat niciun joc la favorite.',
    'not valid date': 'data nu este corectă',
    'international clubs': 'cluburi internaționale',
    'MM.DD.YYYY': 'LL.ZZ.AAAA ',
    'It applies for minimum of 6 events with a minimum quota of 1.25': 'Se aplică pentru minim 6 evenimente la cote de cel puțin 1.25',
    wta: 'wta',
    atp: 'atp',
    'federation cup': 'cupa federației',
    'itf men': 'itf masculin',
    'itf women': 'itf feminin',
    'davis cup': 'cupa davis',
    challenge: 'challenge',
    'challenge women': 'challenge feminin',
    'coreea de sud': 'coreea de sud',
    next3H: '+3H',
    'Search box must contain at least three letters': 'Introdu minim 3 litere pentru a efectua o căutare',
    strong: 'sigură',
    superbets: 'SuperPariuri'
};
