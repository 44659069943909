<template>
    <MaintenanceChecker
        :checkedParts="[MaintenanceType.sport]"
    >
        <template slot="message">
            <div class="maintenance-mode__offer--terminal">
                <h2>{{ $t('Maintenance in progress')}}</h2>
            </div>
        </template>
        <div
            class="flex w-full sports-page"
        >
            <div
                v-sticky="stickyConfig"
                class="nav-sidebar"
                id="sports-page"
            >
                <OfferContainer :isFetcher="true">
                    <SidebarContainer />
                </OfferContainer>
            </div>
            <div class="main--terminal">
                <SportsTimeFilterContainer />
                <MaintenanceChecker
                    v-if="isOnSportLivePage"
                    :checkedParts="[MaintenanceType.live]"
                >
                    <template slot="message">
                        <div class="maintenance-mode__offer">
                            <Illustration large image="/static/img/illustrations/maintenance__sport.svg"/>
                            <div class="maintenance-mode__offer-copy">
                                {{ $t('Live betting is currently under maintenance') }}
                            </div>
                        </div>
                    </template>
                </MaintenanceChecker>
                <MaintenanceChecker
                    v-if="!isOnSportLivePage"
                    :checkedParts="[MaintenanceType.sport]"
                >
                    <template slot="message">
                        <div class="maintenance-mode__offer">
                            <Illustration large image="/static/img/illustrations/maintenance__sport.svg"/>
                            <div class="maintenance-mode__offer-copy">
                                {{ $t('Part of the offer is unavailable during this maintenance') }}
                            </div>
                        </div>
                    </template>
                </MaintenanceChecker>
                <OfferContainer :isUrlRedirecter="true">
                    <SportsOfferContainer parentModule="ui/sportOffer/sports"/>
                </OfferContainer>
            </div>
            <div class="right-sidebar">
                <BetSlipContainer/>
            </div>
        </div>
    </MaintenanceChecker>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import SidebarContainer from '@src/terminal/app/modules/sportOffer/sidebar/SidebarContainer.vue';
    import SportsOfferContainer from
        '@src/terminal/app/modules/sportOffer/sports/content/SportsOfferContainer.vue';
    import OfferContainer from '@src/terminal/app/modules/sportOffer/OfferContainer.vue';
    import BetSlipContainer from '@src/terminal/app/modules/sportOffer/betslip/BetSlipContainer.vue';
    import SportsTimeFilterContainer from
        '@src/terminal/app/modules/sportOffer/sports/timeFilter/SportsTimeFilterContainer.vue';
    import { MaintenanceType } from '@core/models/country/enums';
    import { isEventSolo, isSubNavigationVisible } from 'src/app/layout';
    import { ExpandedEventInUrl } from 'src/app/enums';
    import { setWindowScrollTop } from '@src/app/styles/scripts/uiHelpers';
    import { liveTimeFilterSlug } from '@store/modules/ui/shared/timeFilter/utils';
    import MaintenanceChecker from '@shared/components/MaintenanceChecker.vue';
    import Illustration from '@shared/components/Illustration.vue';
    import modals from '@store/modules/ui/shared/modal/modals';

    export default {
        name: 'SportsPage',
        created() {
            this.stickyConfig = {
                scrollableContentSelector: '.sidebar__responsive__element',
                staticHigherContentSelector: '.sidebar_static__top, .nav-sidebar__wrapper:not(:last-child)',
            };
        },
        data() {
            return {
                MaintenanceType,
            };
        },
        beforeDestroy() {
            this.clearSelectedFeaturedItem();
        },
        watch: {
            [`$route.query.${ExpandedEventInUrl.event}`](e) {
                if (isEventSolo() && e) {
                    this.shouldScroolTop = true;
                }
            },
            '$route.params.timeFilter': function (timeFilter) {
                if (isSubNavigationVisible() && timeFilter === liveTimeFilterSlug) {
                    this.closeSidebar();
                }

                if (timeFilter === liveTimeFilterSlug) {
                    this.subscribeToLiveEventChanges();
                }

                if (!this.isOnSportLivePage && timeFilter !== liveTimeFilterSlug) {
                    this.unSubscribeToLiveEventChanges();
                }
                this.loadOffer();
            },
            showNewSuperbonusPromo(newValue) {
                if (this.isAppReady && newValue) {
                    this.setModal({
                        code: modals.superbonusPromo.code,
                    });
                }
            }
        },
        async updated() {
            if (this.shouldScroolTop) {
                this.shouldScroolTop = false;
                await this.$nextTick();
                setWindowScrollTop(0);
            }
        },
        computed: {
            ...mapGetters('ui/sportOffer', [
                'expandedEvent',
            ]),
            ...mapGetters('navigation', [
                'isOnSportLivePage',
            ]),
            ...mapGetters('data/flags', ['showNewSuperbonusPromo']),
            ...mapGetters('data/country', ['isAppReady']),
            isSubNavigationVisible() {
                return isSubNavigationVisible();
            },
        },
        methods: {
            ...mapActions('data/sportOffer', [
                'subscribeToLiveEventChanges',
                'unSubscribeToLiveEventChanges',
            ]),
            ...mapActions('ui/sportOffer/sports', [
                'clearSelectedFeaturedItem',
                'loadOffer',
            ]),
            ...mapActions('ui/modal', [
                'setModal'
            ])
        },
        components: {
            BetSlipContainer,
            SportsOfferContainer,
            OfferContainer,
            SportsTimeFilterContainer,
            SidebarContainer,
            MaintenanceChecker,
            Illustration,
        }
    };
</script>
