<template>
    <div class="super-bonus-banner-container" @click="toggleExpand">
        <div class="super-bonus-banner-container__title">
            <div> {{title}} </div>
            <div class="icon-container">
                <i class="icon icon-info_circle"/>
            </div>
        </div>
        <div class="super-bonus-banner-container__body">
            <div v-html="notification"/>
            <div>
             <img
                    src="/static/img/illustrations/terminal/superbonus__icon-new.svg"
                    alt="image"
                    width="52"
                    height="52"
                />
            </div>
        </div>
        <div class="super-bonus-banner-container__footer" v-if="isExpanded">
            <div class="label">
                {{$t('close')}}
                <i class="icon icon-chevron_up"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { BonusPercentage } from '@superbet-group/betting.lib.payments';
import { PropType } from 'vue';
export default {
    name: "SuperBonusBanner",
    props: {
        superBonusPercentage: {
            type: Object as  PropType<BonusPercentage>,
            required: true
        }, 
        minNumberOfSelectionsForSuperBonus: {
            type: Number,
            required: true
        },
        minSuperBonusPercentage: {
            type: Number,
            required: true
        },
        minSuperBonusOddValue: {
            type: Number,
            required: true
        }   
    },
    data() {
        return {
            isExpanded: false
        }
    },
    computed: {
        notification() {
            if(this.isExpanded){
                if(this.superBonusPercentage.current) {
                    return this.$t('superbonusBanner.notification.expanded.bonusUnlocked');
                } else {
                    return this.$t('superbonusBanner.notification.expanded.bonusLocked');
                }
            }
            if(this.superBonusPercentage.current == this.superBonusPercentage.maximumPercentage) {
                 return this.$t('superbonusBanner.notification.maximumBonus', {
                    maximumPercentage: this.superBonusPercentage.current
                }); 
            }
            else {
               return this.$t('superbonusBanner.notification.addSelections', {
                    selectionsNeededForNext: this.superBonusPercentage.selectionsNeededForNext,
                    minSuperBonusOddValue: this.minSuperBonusOddValue,
                    nextSuperBonusPercentage: this.superBonusPercentage.next
                });
            }
        },
      title() {
        if(this.superBonusPercentage.current) {
            if(this.superBonusPercentage.current === this.superBonusPercentage.maximumPercentage){
                 return this.$t('superbonusBanner.title.maximumBonus', {maximumPercentage: this.superBonusPercentage.current}).toString();
            }
            return this.$t('superbonusBanner.title.bonusUnlocked').toString();
        } else {
            return this.$t('superbonusBanner.title.bonusLocked', {
                currentNumberOfValidSelections: this.minNumberOfSelectionsForSuperBonus - this.superBonusPercentage.selectionsNeededForNext,
                minNumberOfSelectionsForSuperBonus: this.minNumberOfSelectionsForSuperBonus,
                nextSuperBonusPercentage: this.superBonusPercentage.next
            }).toString();
        }
      },
    },
    methods: {
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        }
    }
}
</script>

<style lang="scss" scoped>
.super-bonus-banner-container{
    display: flex;
    padding: 8px 11px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: rgba(255, 184, 46, 0.16);
    cursor: pointer;

    &__title{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(247, 202, 3, 1);
        font-size: 16px;
        font-style: italic;
        font-weight: 900;
        line-height: 20px;
        letter-spacing: 0.125px;
        text-transform: uppercase;
    }

    &__body{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(247, 202, 3, 1);
    }

    &__footer{
        padding: 8px;
        color: rgba(247, 202, 3, 1);

        .label{
            display: flex;
            align-items: center;
        }
    }   
}
</style>
