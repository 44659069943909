import config from '@config';
import * as Sentry from '@sentry/vue';
import common from '@src/config/common';
import { VueConstructor } from 'vue';

export default function reportErrors(vue: VueConstructor<Vue>) {
    Sentry.init({
        Vue: vue,
        dsn: config.services.sentry.dsn,
        environment: config.environment.appEnv,
        release: config.environment.version,
    });
    Sentry.setTag('platform', common.environment.platform);
}
