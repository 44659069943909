import {
    isEmpty as _isEmpty,
    orderBy as _orderBy,
    last as _last,
} from '@lodash';
import { Dispatch } from 'vuex';
import { IState } from '@app/store';
import {
    buildTreeFromMatches,
    orderTree,
    INode,
    extractBreadcrumbsFromRoute,
} from '@app/utils/sidebar';
import config from '@config';
import Match from '@core/models/offer/Match';
import slugify from '@core/utils/url/slugify';
import { routeNames } from '@app/router/constants';
import { OfferType } from '@core/models/offer/enums';
import { ItemType, PredefinedItems } from '@app/store/modules/ui/sportOffer/sports/enums';
import i18n from '@src/app/localization/i18n';
import { capitalize } from '@shared/filters';

const ALL_ID = -1000;

interface SelectedTreeItem {
    id?: INode['name'];
    name?: string;
    type: ItemType | PredefinedItems.all;
}

interface ISportResultsUIState {}

export default (parentStorePath: string) => ({
    namespaced: true,
    state: {},
    getters: {
        areMatchesExpandable(state: ISportResultsUIState, getters: any, rootState: IState, rootGetters: any) {
            return rootGetters['navigation/route'].name === routeNames.sportResults;
        },
        offerType(state: ISportResultsUIState, getters: any, rootState: IState, rootGetters: any) {
            const routeName = rootGetters['navigation/route'].name;
            if (routeName === routeNames.sportResults) {
                return OfferType.preEvent;
            }
            if (routeName === routeNames.liveResults) {
                return OfferType.live;
            }
            return null;
        },
        expandedEventId: (state: ISportResultsUIState, getters: any, rootState: IState, rootGetters: any) => {
            const matchId = rootGetters[`${parentStorePath}/expandedEventId`];
            if (!matchId || !rootGetters['data/results/sport/resultsMap'][matchId]) {
                return null;
            }
            return matchId;
        },
        selectedDate(state: any, getters: any, rootState: IState, rootGetters: any) {
            return rootGetters[`${parentStorePath}/selectedDate`];
        },
        breadcrumbIds(state: any, getters: any, rootState: any, rootGetters: any) {
            const bcIds = extractBreadcrumbsFromRoute(rootGetters['navigation/route'], getters.tree);
            return bcIds.length ? bcIds : [ALL_ID];
        },
        tree(state: any, getters: any, rootState: IState, rootGetters: any) {
            const events = getters.filteredMatchesByDateAndOffer;
            const {
                sports,
                categories,
                tournaments,
            } = rootState.data.sportOffer;
            const struct = {
                sports,
                categories,
                tournaments,
            };
            const result = [{
                id: ALL_ID,
                name: capitalize(i18n.t('all').toString()),
                children: ([] as INode[]),
                eventCount: events ? events.length : 0,
            }];
            if (events && !Object.values(struct).some(_isEmpty)) {
                const tree = buildTreeFromMatches(events, struct);
                result.push(...orderTree(Object.values(tree)));
            }
            return result;
        },
        selectedTreeItem(state: ISportResultsUIState, getters: any) {
            const breadcrumbIds = getters.breadcrumbIds as (string | number)[];
            const selectedItem = {
                type: PredefinedItems.all,
            } as SelectedTreeItem;

            if (breadcrumbIds[0] !== ALL_ID) {
                switch (breadcrumbIds.length) {
                    case 3:
                        selectedItem.type = ItemType.tournamentId;
                        break;
                    case 2:
                        selectedItem.type = ItemType.categoryId;
                        break;
                    case 1:
                        selectedItem.type = ItemType.sportId;
                        break;
                }
                selectedItem.id = `${_last(breadcrumbIds)}`!;
            }
            return selectedItem;
        },
        filteredMatches(state: any, getters: any) {
            const events = getters.filteredMatchesByDateAndOffer as Match[];
            const [sportId, categoryId, tournamentId] = getters.breadcrumbIds;
            let filteredEvents = events;
            if (sportId !== ALL_ID) {
                filteredEvents = events.filter(e =>
                    e.sportId === sportId
                    && (!categoryId || categoryId === e.categoryId)
                    && (!tournamentId || tournamentId === e.tournamentId));
            }
            return _orderBy(filteredEvents, config.app.sportOffer.eventGridSort);
        },
        filteredMatchesByDateAndOffer(state: any, getters: any, rootState: IState, rootGetters: any) {
            const results = rootGetters['data/results/sport/results'] as Match[];
            const offerType = getters.offerType as OfferType;
            const filters = [
                (e: Match) => e.date! >= startDate && e.date! < endDate
            ];
            if (offerType) {
                filters.push((e: Match) => offerType
                    && e.offerStatus
                    && e.offerStatus[offerType] !== undefined || false);
            }
            const [startDate, endDate] = rootGetters[`${parentStorePath}/timeWindow`];
            return results.filter(e => filters.every(filter => filter(e)));
        }
    },
    actions: {
        selectTreeItem({ dispatch } : { dispatch: Dispatch }, selectTreeItems: SelectedTreeItem[]) {
            const [
                sportSlug,
                categorySlug,
                tournamentSlug,
            ] = selectTreeItems.map(ti => slugify(ti.name!));

            dispatch('navigation/push', { params: { sportSlug, categorySlug, tournamentSlug } } , { root: true });
        },
        toggleExpandedEventId: (
            { dispatch }: { dispatch: Dispatch },
            eventId: number
        ) => {
            dispatch(`${parentStorePath}/toggleExpandedEventId`, eventId, { root: true });
        },
    }
});
