import Vue from 'vue';
import { Route, RouteRecord } from 'vue-router';
import { findLast as _findLast } from '@lodash';
import store from '../store';
import { VUE_ROUTER_CONTROLLED } from './constants';
import i18n from '@app/localization/i18n';
import HeaderContainer from '@modules/layout/header/HeaderContainer.vue';
import FooterComponent from '@modules/layout/footer/components/FooterComponent.vue';
import { getTimeFilterSlugs } from '@store/modules/ui/shared/timeFilter/utils';

export const timeFilterOptions = `:timeFilter(${getTimeFilterSlugs().join('|')})`;

export function getDefaultLayout(contentComponent: any) {
    return {
        content: contentComponent,
        header: HeaderContainer,
        footer: FooterComponent,
    };
}

/**
 * This helper function chains guards so we can use multiple guards.
 *
 * For example:
 *
 * beforeEnter: (chainGuards)([loginRequired, isAdmin])
 *
 */
export function chainGuards(guards: any) {
    if (!Array.isArray(guards)) {
        throw new Error('You must specify an array of guards');
    }

    return (to: Route, from: Route, next: Function) => {
        return evaluateGuards(guards, to, from, next);
    };
}

export function isUndefined(value: any) {
    return value === undefined;
}

export function evaluateGuards(guards: Function[], to: Route, from: Route, next: Function) {
    const guardsLeft = guards.slice(0);
    const nextGuard = guardsLeft.shift();

    if (isUndefined(nextGuard)) {
        next();
        return;
    }

    nextGuard!(to, from, (nextArg: any) => {
        if (isUndefined(nextArg)) {
            evaluateGuards(guardsLeft, to, from, next);
            return;
        }

        next(nextArg);
    });
}

export function updateTitle(to: Route) {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = _findLast(to.matched, r => r.meta && r.meta.title) as RouteRecord;

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title.toString();
    }
}

export function updateMetaTags(to: Route) {
    // Remove any stale meta tags from the document using the key attribute we set below.
    // 'parentNode' will never be 'null' because the 'data-vue-router-controlled' attribute
    // is set exclusively on meta tags (children of the '<head>' element).
    Array.from(document.querySelectorAll(`[${VUE_ROUTER_CONTROLLED}]`)).map(el => el.parentNode!.removeChild(el));

    // Find the nearest route element with meta tags.
    const nearestWithMeta = _findLast(to.matched, r => r.meta && r.meta.metaTags) as RouteRecord;
    if (!nearestWithMeta) {
        return;
    }

    nearestWithMeta.meta.metaTags.map((tagDef: Record<string, string>) => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            const value = i18n.t(tagDef[key]).toString();
            tag.setAttribute(key, value);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute(VUE_ROUTER_CONTROLLED, '');

        return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: HTMLMetaElement) => document.head.appendChild(tag));
}
