<template>
    <div>
        <CheckTicketNsoft v-if="config.environment.isNSoft"></CheckTicketNsoft>
        <CheckTicketInhouse v-else></CheckTicketInhouse>
    </div>
</template>

<script>
    import CheckTicketNsoft from '@src/terminal/app/modules/checkTicket/CheckTicketNsoft.vue';
    import CheckTicketInhouse from '@src/terminal/app/modules/checkTicket/CheckTicketInhouse.vue';
    import commonConfig from '@src/config/common';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'CheckTicket',
        components: {
            CheckTicketNsoft,
            CheckTicketInhouse
        },
        data() {
            return {
                config: commonConfig
            };
        },
        created() {
            //TODO Temp fix to clear modal due to check ticket -> main page -> check ticket redirects
           if(this.modal) {
               this.closeModals();
           }
        },
        computed: {
           ...mapGetters('ui/modal', ['modal'])
        },
        methods: {
            ...mapActions('ui/modal', [
                'closeModals'
            ]),
        }
    };
</script>

<style lang="scss">
.app-main {
    height: auto;
    min-height: initial;
    padding: 0;
    margin: 0;
}
</style>
