<template>
    <div class="modal__overlay">
        <div class="modal__container">
            <div class="modal__container__header">
                <i
                    @click="closeModal"
                    class="icon icon-close"
                />
            </div>
            <div class="modal__container__body">
                <img
                    src="/static/img/illustrations/terminal/superbonus__icon-new.svg"
                    alt="image"
                    width="208"
                    height="208"
                >
                <span class="super-bonus__details-title"> {{$t('superbonusPromoModal.title')}}</span>
                <div class="super-bonus__details-container">
                    <ul>
                        <li v-for="item in superbonusDetails" :key="item">
                            {{ $t(item) }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal__container__footer">
                <button class="btn modal-btn--dual--terminal" @click="closeModal()">{{ $i18n.t('superbonusPromoModal.buttonLabel') }}</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapActions } from "vuex";

export default {
    name: "SuperBonusPromoModal",
    computed: {
        superbonusDetails() {
            return [
                "superbonusPromoModal.content.competitions",
                "superbonusPromoModal.content.numberOfLegs",
                "superbonusPromoModal.content.validSelectionTypes",
                "superbonusPromoModal.content.minOddValue",
            ]
        }
    },
    methods: {
        ...mapActions('ui/modal', [
            'closeModal'
        ]),
    }
}
</script>

<style lang="scss" scoped>
.modal__container{
    width: 598px;
    max-width: 598px;
    height: 675px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: #19191D;
    &__header{
        display: flex;
        justify-content: flex-end;
        position: relative;

        .icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
            color: rgba(63, 65, 68, 1);
            align-self: flex-start;
            margin-left: 12px;
            font-size: 24px;
        }
    }

    &__body{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;

        ul{
            list-style-type: disc;
            list-style-position: inside;
        }

        .super-bonus__details-title{
            color: rgba(255, 255, 255, 1);
            text-align: center;
            font-size: 32px;
            font-style: italic;
            font-weight: 900;
            line-height: 32px;
            letter-spacing: 1.2px;
            text-transform: uppercase;

            padding: 47px 0 66px 0;
        }
        .super-bonus__details-container{
            display: flex;
            justify-content: center;
            align-items: center;

            padding: 8px 12px;
            align-items: flex-start;
            gap: 8px;

            border-radius: 6px;
            background: rgba(39, 42, 44, 1);
            color: rgba(255, 255, 255, 0.8);

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    &__footer{
        display: flex;
        justify-content: center;

        .btn {
            width: 100%;
        }
    }
}
</style>
