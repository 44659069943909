/* tslint:disable */
import {
    forEach as _forEach,
    get as _get,
    isEmpty as _isEmpty,
    differenceWith as _differenceWith,
    keyBy as _keyBy,
    map as _map
} from '@lodash';
import {ActionContext, Commit, Dispatch} from 'vuex';
import {Subscription} from 'rxjs';
import StoreUtil from '@store/StoreUtil';
import Odd from '@core/models/offer/Odd';
import SportSelection from '@core/models/betSlip/SportSelection';
import OfferService from '@core/services/offer/OfferService';
import SelectionChange from '@models/betSlip/SelectionChange';
import System from '@models/betSlip/System';
import ISerializedSelection from '@models/betSlip/serialization/ISerializedSelection';
import config from '@config';
import IBetSlipState from './types';
import {
    ADD_ERROR,
    ADD_SELECTION,
    CLEAR_CHANGES,
    CLEAR_ERRORS,
    CLEAR_SELECTED_SYSTEMS_MIN_NUMBER,
    CLEAR_SELECTION_ERRORS,
    CLEAR_SELECTIONS,
    DELETE_CHANGE,
    DELETE_SELECTED_SYSTEM_MIN_NUMBER,
    REMOVE_ERROR,
    REMOVE_SELECTION,
    SELECTION_FIX,
    SET_AUTO_UPDATE_CHANGES,
    SET_CHANGE,
    SET_IS_LOADING,
    SET_IS_PAYOUT_LOCKED,
    SET_PAYOUT,
    SET_PURCHASE_TYPE,
    SET_SELECTED_SYSTEM_MIN_NUMBER,
    SET_SELECTION_ADD_ERROR,
    SET_SELECTION_ERRORS,
    SET_STAKE,
    SET_TYPE,
    SET_UNDO_SELECTIONS,
    SET_UNDO_SELECTIONS_TIMER_ID,
    SET_UNDO_STAKE,
    SET_UNDO_SYSTEMS,
    TOGGLE_AUTO_UPDATE_CHANGES,
    TOGGLE_SELECTED_SYSTEM_MIN_NUMBER,
    TOGGLE_SELECTION_IS_FIXED,
    TOGGLE_SELECTION_ODD,
    TOGGLE_SYSTEM_SELECTED,
    UPDATE_SELECTION,
    UPDATE_SELECTION_EVENT,
    UPDATE_SELECTION_ODD,
} from './mutationTypes';
import BetSlipHelper from './Helpers';
import {BetSlipPurchaseType, BetSlipType, SelectionType} from '@models/shared/betSlip/betSlipEnums';
import ISerializedBetSlip from '@models/betSlip/serialization/ISerializedBetSlip';
import { EventStatus, OddSourceType, TicketType } from '@models/tickets/enums';
import IValidationResult, {validationResultService} from '@models/betSlip/validation/IValidationResult';
import {BetSlipErrorFactory} from '@models/betSlip/validation/IBetSlipError';
import IValidationService from '@core/services/betSlip/validation/IValidationService';
import ServerConfigProvider from '@core/providers/configuration/ServerConfigProvider';
import {IState} from '@app/store';
import Ticket, {EventCondensed} from '@models/tickets/Ticket';
import {routeNames} from '@app/router';
import Helpers from '@store/modules/ui/shared/betSlip/Helpers';
import {ITicketsStackState} from '@store/modules/ui/shared/ticketsStack/ticketsStackIndex';
import {PayoutService} from '@core/services/betSlip/PayoutService';
import {EMPTY_PAYOUT} from '@models/betSlip/Payout';
import AllowancesService, {
    AllowancesOutcome
} from '@src/terminal/core/services/allowances/AllowancesService';
import Match from "@models/offer/Match";
import * as Sentry from "@sentry/vue";
import ISelection from '@models/betSlip/ISelection';
import FeatureFlagService from "@src/terminal/core/services/flags/FeatureFlagService";
import {
    checkIfBoostedOddConflictOccurs,
    ReplacementType,
} from '@utils/boostedOdds/conflicts';
import Modals from "@store/modules/ui/shared/modal/modals";
import SavedOdd from '@core/models/base/SavedOdd';
import { mergeMatch } from '@store/modules/data/sportOffer/helpers';

export function findSelectionWithOdd(
    selections: ISelection[],
    oddUuid: string | null
): number | null {

    const selectionIndex = selections.findIndex((s) => s.getOddUuid() === oddUuid);

    if (selectionIndex !== -1) {
        return selectionIndex;
    }

    return null;
}
export default (validationFactory: () => IValidationService) => {
    const subscriptions: Record<string, Subscription> = {};

    return {
        setStake(
            { commit, dispatch, rootGetters }: { commit: Commit, dispatch: Dispatch, rootGetters: any },
            stake: number
        ): void {
            commit(SET_STAKE, stake);
            dispatch('validateBetSlip');
            commit(SET_STAKE, Math.min(rootGetters['data/country/config'].betSlip.stakeMax, stake));
            dispatch('calculatePayout');
        },
        setDefaultStake(
            { dispatch }: { dispatch: Dispatch },
        ): void {
            dispatch('setStake', ServerConfigProvider.getConfig().betSlip.stakeMin);
        },
        setPurchaseType(
            { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
            purchaseType: BetSlipPurchaseType
        ): void {

            commit(SET_PURCHASE_TYPE, purchaseType);
            dispatch('calculatePayout');
            dispatch('validateBetSlip');
        },
        setType(
            { state, commit, dispatch }: { state: IBetSlipState, commit: Commit, dispatch: Dispatch },
            type: BetSlipType
        ): void {

            commit(SET_TYPE, type);
            if (type === BetSlipType.system) {
                const allowedUnFixedCount = ServerConfigProvider.getConfig().betSlip.maxSelectedNumbers / 2 + 1;
                const unFixedSelections = state.selections.filter((s: ISelection) => !s.isFixed);
                const unFixedCount = unFixedSelections.length;
                let toFixCount = unFixedCount - allowedUnFixedCount;
                if (toFixCount > 0) {
                    unFixedSelections
                        .forEach((s: ISelection) => {
                            if (toFixCount > 0) {
                                dispatch('setSelectionFix', s);
                                toFixCount -= 1;
                            }
                        });
                }
            }
            dispatch('calculatePayout');
            dispatch('validateBetSlip');
        },
        setSelectionAddError: StoreUtil.createSimpleMutatorAction(SET_SELECTION_ADD_ERROR),
        removeBetSlipError: StoreUtil.createSimpleMutatorAction(REMOVE_ERROR),
        setIsLoading: StoreUtil.createSimpleMutatorAction(SET_IS_LOADING),

        acceptChange(
            { commit, state, dispatch }: { commit: Commit, state: IBetSlipState, dispatch: Dispatch },
            change: SelectionChange
        ) {
            commit(DELETE_CHANGE, change.selection.getEventId());

            const selection = BetSlipHelper.getSelection(state.selections, change.selection);
            if (selection) {
                commit(UPDATE_SELECTION, { selection, newSelection: change.newSelection });
                dispatch('calculatePayout');
                dispatch('validateBetSlip');
            }
        },

        toggleAutoUpdateChanges(
            { commit, state, dispatch }: { commit: Commit, state: IBetSlipState, dispatch: Dispatch }
        ): void {
            commit(TOGGLE_AUTO_UPDATE_CHANGES);
            if (state.autoUpdateChanges) {
                dispatch('acceptAllChanges');
            }
        },

        setAutoUpdateChanges(
            { commit }: { commit: Commit }
        ): void {
            commit(SET_AUTO_UPDATE_CHANGES, true);
        },

        acceptAllChanges({ state, dispatch }: { state: IBetSlipState, dispatch: Dispatch }): void {
            _forEach(state.changes, (change) => dispatch('acceptChange', change));
        },

        declineChange(
            { commit, state, dispatch }:
                { commit: Commit, state: IBetSlipState, dispatch: Dispatch },
            change: SelectionChange
        ): void {
            commit(DELETE_CHANGE, change);

            const selection = BetSlipHelper.getSelection(state.selections, change.selection);
            if (selection) {
                dispatch('removeSelection', { selection: selection });
            }
        },

        clear(
            { commit, state, dispatch }: { commit: Commit, state: IBetSlipState, dispatch: Dispatch }
        ) {
            state.selections.forEach((s) => {
                if (s.isSportType()) {
                    if(s.isBetBuilderSource() && s.odd) {
                        dispatch('data/sportOffer/removeBetBuilderSubscription', s.getOddUuid(), { root: true });
                    }
                    dispatch('removeSelectionSubscription', s.getEventId());
                }
            });

            commit(SET_UNDO_SELECTIONS, state.selections);
            commit(SET_UNDO_STAKE, state.stake);
            commit(SET_UNDO_SYSTEMS, state.selectedSystemsMinNumbers);
            const timerId = setTimeout(
                () => {
                    dispatch('_clearUndoSelections');
                },
                config.app.betSlip.undoClearDuration
            );
            commit(SET_UNDO_SELECTIONS_TIMER_ID, timerId);
            commit(CLEAR_SELECTIONS);
            dispatch('setDefaultStake');
            commit(CLEAR_CHANGES);
            commit(CLEAR_ERRORS);
            commit(CLEAR_SELECTED_SYSTEMS_MIN_NUMBER);
            dispatch('calculatePayout');
            dispatch('validateBetSlip');
            dispatch('data/tickets/clearAllErrors', null, { root: true });
        },

        undoClear(
            { commit, dispatch, state }: { commit: Commit, dispatch: Dispatch, state: IBetSlipState }
        ) {
            if (state.undoSelections) {
                state.undoSelections.forEach((selection) => dispatch('addSelection', { selection }));
            }
            if (state.undoSelectedSystemsMinNumbers) {
                commit(SET_SELECTED_SYSTEM_MIN_NUMBER, state.undoSelectedSystemsMinNumbers);
            }
            if (state.undoStake) {
                dispatch('setStake', state.undoStake);
            }
            dispatch('_clearUndoSelections');
        },
        async toggleAddRemoveSelection(
            { state, dispatch, commit, rootGetters }:
                { state: IBetSlipState, dispatch: Dispatch, commit: Commit, rootGetters: any },
            {
                eventId,
                oddId,
                specialBetValue,
                type,
            }: {
                eventId: number,
                oddId: number,
                specialBetValue?: string,
                type?: string,
            }
        ): Promise <void> {
            dispatch('_clearUndoSelections');
            let event;
            let odd;
            let newSelection: ISelection;

            event = rootGetters['data/sportOffer/eventsMap'][eventId];
            odd = event.getOddById(oddId, specialBetValue) as Odd;
            newSelection = SportSelection.createItem(event, odd);

            const boostedOddConflict = await checkIfBoostedOddConflictOccurs(newSelection, state.selections);
            if (boostedOddConflict) {
                const boostedModal = {
                    code: Modals.boostedOddConflict.code,
                    data: {
                        containerClass: 'boosted-odd-modal',
                        data: {
                            betSlipNamespace: 'ui/sportOffer/betSlip',
                            selections: state.selections,
                            newSelection,
                            isNewOddBoosted: boostedOddConflict === ReplacementType.NewBoosted,
                        },
                    },
                    afterClose: () => {
                        dispatch('clear');
                        dispatch('addSelection', { selection: newSelection });
                    },
                };
                await dispatch('ui/modal/setModal', boostedModal, { root: true });
                return;
            }
            const sameEventSelections = BetSlipHelper.getSameEventSelections(state.selections, newSelection);
            const selectionInSameMarketLine = sameEventSelections.find((s) => BetSlipHelper.isSameMarketLine(newSelection, s));
            const newMarketId = newSelection.getMarketId() || 0;
            const outcome1: AllowancesOutcome = {
                marketId: newMarketId,
                outcomeId: newSelection.getOddId() || 0,
                specifiers: JSON.stringify(newSelection.odd?.specifiers),
                isInplay: newSelection.isOngoing()
            };
            // 1. First selection for an event, just add it
            if (sameEventSelections.length === 0) {
                return dispatch('addSelection', {
                    selection: newSelection
                });
            }
            // 2. There is an existing selection for the same event
            const isExactSameSelection = sameEventSelections.some((s) => s.isEqual(newSelection));
            // 2.1. Toggle the exact same odd (0)
            if (isExactSameSelection) {
                return await dispatch('removeSelection', { selection: selectionInSameMarketLine });
            } else if (selectionInSameMarketLine) {
                // 2.2. Swap within same market line (1)
                commit(UPDATE_SELECTION, {
                    selection: selectionInSameMarketLine,
                    newSelection,
                });
                dispatch('validateBetSlip');
                return;
            }
            const useAllowanceV2 = FeatureFlagService.getInstance().useAllowancesV2();
            // 3. Remove all non-combinable market(that are not in Allowances)
            sameEventSelections
                .filter(
                    existingSelection => {
                        const existingId = existingSelection.getMarketId() || 0;
                        if (useAllowanceV2) {
                            if(!existingId) return true;
                            const outcome2: AllowancesOutcome = {
                                marketId: existingId,
                                outcomeId: existingSelection.getOddId() || 0,
                                specifiers: JSON.stringify(existingSelection.odd?.specifiers),
                                isInplay: existingSelection.isOngoing()
                            };
                            return !AllowancesService.getInstance().areMarketAllowedV2(
                                outcome1,
                                outcome2
                            );
                        } else {
                            return !AllowancesService.getInstance().areMarketsAllowed(newMarketId, existingId);
                        }
                    }
                )
                .forEach(removeSelection);
            return dispatch('addSelection', {
                selection: newSelection
            });
            function removeSelection(selection: ISelection): any {
                dispatch('removeSelection', { selection: selection });
            }
        },
        async addSelection(
            { commit, dispatch, getters, rootGetters }:
                { commit: Commit, dispatch: Dispatch, getters: any, rootGetters: any },
            {
                selection,
                index,
                runValidation = true,
                isRecreate = false
            }: {
                selection: ISelection,
                index?: number,
                runValidation: boolean
                isRecreate: boolean
            }) {
            if (runValidation) {
                if (getters.isSystem && getters.nonFixedSelectionsCount >= config.app.betSlip.maxSystemSelections) {
                    // If there is already maximum number of system bets on the ticket, try to add an item
                    // with a fix flag set. It will be available until overall maximum number of
                    // of bets is reached.
                    selection.isFixed = true;
                }
                /**
                 * Because when we are doing add we validate against candidate, not betSlip current selections
                 * that's why we are doing explicit validation, and if we have any errors candidate can't be
                 * added, and all the previous validation stays as is: nothing has changed!
                 */
                commit(SET_SELECTION_ADD_ERROR, null);

                const isInPlayAvailable = rootGetters['data/flags/isInPlayAvailable'] as boolean;

                const validationResult: IValidationResult = validationFactory()
                    .validate(
                        {
                            selections: [
                                selection,
                                ...getters.selections,
                            ],
                            purchaseType: getters.purchaseType,
                            stake: getters.stake,
                            autoUpdateChanges: getters.autoUpdateChanges,
                            selectedSystems: getters.selectedSystems,
                            type: getters.type,
                            payout: getters.payout,
                        },
                        false,
                        isInPlayAvailable,
                    );

                const error = validationResultService
                    .getFirstErrorWithAddSelectionRejection(validationResult, `${selection.getEventId()}`);
                if (error) {
                    commit(SET_SELECTION_ADD_ERROR, error);
                    return;
                }
            }

            commit(ADD_SELECTION, {
                index,
                selection,
            });

            if (selection.isSportType()) {
                dispatch('addSelectionSubscription', { selection, key: selection.getEventId() });
            }
            if (!isRecreate) {
                dispatch('updateSelectionFromServer', selection);
                dispatch('validateBetSlip');
                dispatch('calculatePayout');
            }
        },
        removeSelection(
            { commit, dispatch, getters, state }: { commit: Commit, dispatch: Dispatch, getters: any, state: any }, { selection }: { selection: ISelection }
        ) {
            commit(DELETE_CHANGE, selection.getEventId());
            commit(REMOVE_SELECTION, selection);
            commit(DELETE_SELECTED_SYSTEM_MIN_NUMBER, getters.selections.length + 1);
            if(selection.isBetBuilderSource() && selection.odd) {
                dispatch('data/sportOffer/removeBetBuilderSubscription', selection.getOddUuid(), { root: true});
            } else if (selection.isSportType()) {
                dispatch('removeSelectionSubscription', selection.getEventId());
            }
            dispatch('calculatePayout');
            dispatch('validateBetSlip');
        },
        toggleSelectionOdd(
            { commit, dispatch }: { commit: Commit, dispatch: Dispatch, getters: any },
            selection: ISelection
        ) {
            commit(TOGGLE_SELECTION_ODD, selection);
            commit(DELETE_CHANGE, selection.getEventId());
            dispatch('validateBetSlip');
            dispatch('calculatePayout');
        },

        addSelectionSubscription(
            { state, dispatch }: { state: IBetSlipState, dispatch: Dispatch },
            { selection, key }: { selection: ISelection, key: string }
        ) {
            if (!subscriptions[key]) {
                const subscription = OfferService.getInstance().subscribeToEventSSE(selection.getEventId(),
                    async ({ event }) => {
                        const currentSelection = BetSlipHelper.findSelectionByEventId(
                            state.selections,
                            selection.getEventId(),
                            selection.getItemType(),
                        );
                        if (!currentSelection || !event) return;
                        // Merge data so we have access to all odds
                        const match = mergeMatch(Object.freeze(currentSelection.event), event);

                        const selectionOddId = currentSelection.odd ? currentSelection.getOddId() : currentSelection.savedOdd?.id;
                        const selectionOddSpecialValue = currentSelection.odd ? currentSelection.getOddSpecialBetValue() : currentSelection.savedOdd?.specialBetValue;

                        const oddKey = `${selectionOddId} ${selectionOddSpecialValue}`;
                        const odd = selectionOddId ? match.getOddsMap()[oddKey] : null;
                        const newSelection = SportSelection.createItem(match, odd);

                        if (currentSelection.savedOdd) {
                            newSelection.savedOdd = currentSelection.savedOdd;
                        } else if (currentSelection.odd && !odd) {
                            newSelection.savedOdd = SavedOdd.createOdd(currentSelection.odd);
                        }
                        dispatch('processSelectionChange', {newSelection, selection: currentSelection});
                        dispatch('validateBetSlip');
                    });
                subscriptions[key] = subscription;
            }
        },

        removeSelectionSubscription(
            { }: {},
            key: number | string
        ) {
            if(subscriptions[key]) {
                subscriptions[key].unsubscribe();
                delete subscriptions[key];
            }
        },
        removeAllSubscriptions(): void {
            Object.keys(subscriptions).forEach(key => {
                if (subscriptions[key]) {
                    subscriptions[key].unsubscribe();
                    delete subscriptions[key];
                }
            });
        },
        calculatePayout: StoreUtil.asyncErrorGuard(async (
            { commit, getters, state }: { commit: Commit, getters: any, state: IBetSlipState }
        ) => {
            if (state.isPayoutLocked) {
                return;
            }
            if (getters.selections.length === 0) {
                commit(SET_PAYOUT, EMPTY_PAYOUT);
                return;
            }

            if (getters.selectionsWithOdd.length === 0) {
                commit(SET_PAYOUT, EMPTY_PAYOUT);
                return;
            }

            const betSlipData = {
                selections: getters.selectionsWithOdd,
                purchaseType: getters.purchaseType,
                selectedSystems: getters.selectedSystems,
                stake: getters.stake,
                type: getters.type === BetSlipType.simple || getters.isOnlyXXSelected ? BetSlipType.simple : BetSlipType.system, //  If only one system is selected, it should be treated as a simple betslip
                stakePerCombination: getters.stakePerCombination,
            };

            const payout = PayoutService.getInstance().getPayout(betSlipData);

            commit(SET_PAYOUT, payout);
        }),

        // we wanna instant fresh data from server when we put something in betSlip
        // offer have throttle so they can lag from latest data.
        updateSelectionFromServer: StoreUtil.asyncErrorGuard(
            async (
                { dispatch }: { dispatch: Dispatch },
                selection: ISelection
            ): Promise<void> => {

                const newSelection = await BetSlipHelper.fetchSelection({
                    oddId: selection.getOddId(),
                    eventId: selection.getEventId(),
                    specialBetValue: selection.getOddSpecialBetValue(),
                    type: selection.getItemType(),
                    sourceType: selection.getSourceType(),
                });

                if (newSelection) {
                    newSelection.isFixed = selection.isFixed;
                    dispatch('processSelectionChange', { selection, newSelection });
                }
            }),

        toggleSelectionIsFixed(
            { state, commit, dispatch }: { state: IBetSlipState, commit: Commit, dispatch: Dispatch },
            selection: ISelection
        ): void {
            if (selection.isFixed) {
                const allowedUnFixedCount = ServerConfigProvider.getConfig().betSlip.maxSelectedNumbers / 2 + 1;
                const unFixedSelections = state.selections.filter((s: ISelection) => !s.isFixed);
                const unFixedCount = unFixedSelections.length;
                const unFixedRemaining = allowedUnFixedCount - unFixedCount;
                if (unFixedRemaining > 0) {
                    commit(TOGGLE_SELECTION_IS_FIXED, selection);
                    dispatch('calculatePayout');
                } else {
                    dispatch('setSelectionFix', selection);
                }
            } else {
                commit(TOGGLE_SELECTION_IS_FIXED, selection);
                dispatch('calculatePayout');
            }
        },

        setSelectionFix(
            { commit }: { commit: Commit },
            selection: ISelection
        ): void {
            commit(SELECTION_FIX, selection);
        },

        deserializeBetSlip: StoreUtil.asyncErrorGuard(async (
            { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
            serializedBetSlip: ISerializedBetSlip
        ) => {
            if (!serializedBetSlip) {
                return;
            }

            // lock payout calculation until we finish deserialization! we wanna only one calculation
            commit(SET_IS_PAYOUT_LOCKED, true);
            dispatch('setIsLoading', true);
            commit(SET_STAKE, serializedBetSlip.stake);
            commit(SET_TYPE, serializedBetSlip.type);
            commit(SET_PURCHASE_TYPE, serializedBetSlip.purchaseType);

            await Promise.all(serializedBetSlip.selections
                .map((s) => dispatch('_fetchSelection', s)));

            commit(SET_SELECTED_SYSTEM_MIN_NUMBER, serializedBetSlip.selectedSystemsMinNumbers);
            dispatch('setIsLoading', false);
            commit(SET_IS_PAYOUT_LOCKED, false);
            dispatch('calculatePayout');
            dispatch('validateBetSlip');
        }),
        updateSelectionOdd: (
            { commit, state }: { commit: Dispatch, state: IBetSlipState }, odd: Odd
        ): void => {
            const selectionData = findSelectionWithOdd(state.selections, odd.uuid);

            if (selectionData) {
                commit(UPDATE_SELECTION_ODD, { odd, selectionData });
            }
        },
        updateStaleMatchOdds: async (
                { dispatch, getters }: { dispatch: Dispatch, getters: any }
            ) => {
                const selections = getters.selections;
                try {
                    const matchesWithStaleOdds = async (): Promise<Match[]> => {
                        const currentMatchIds = _map(selections, 'event.id');
                        const currentMatches = _map(selections, 'event');
                        const updatedMatches = await OfferService.getInstance().getEventsById(currentMatchIds);
                        const diffCheck = (a: Match, b: Match) => (
                            a.matchTimestamp === b.matchTimestamp ||
                            a.incrementId === b.incrementId
                        );
                        return _differenceWith(updatedMatches, currentMatches, diffCheck);
                    }

                    const staleMatches = await matchesWithStaleOdds();

                    if (staleMatches.length > 0) {
                        const selectionsKeyedByEventId = _keyBy(selections, 'event.id');
                        staleMatches.forEach((staleMatch: Match) => {
                            const selection = selectionsKeyedByEventId[staleMatch.id];
                            // to add an selectionError we need to create a selection from the new match
                            const selectionOddId = selection.getOddId();
                            const oddKey = `${selectionOddId} ${selection.getOddSpecialBetValue()}`;
                            const odd = selectionOddId ? staleMatch.getOddsMap()[oddKey] : null;
                            const newSelection = SportSelection.createItem(staleMatch, odd);
                            dispatch('processSelectionChange', { selection, newSelection });
                            dispatch('validateBetSlip');
                        });
                    } else {
                        Sentry.captureMessage('Odd difference error triggered odd update, but no differences found');
                    }
                } catch (err) {
                    Sentry.captureException(err);
                }
            },

        submitTicket: StoreUtil.asyncErrorGuard(
            async (
                { dispatch, getters }: { dispatch: Dispatch, getters: any }
            ) => {
                await dispatch('validateBetSlip', true);
                if (getters.errors.length !== 0 || !_isEmpty(getters.selectionErrors)) {
                    return;
                }

                return dispatch(
                    'data/tickets/submitTicket',
                    {
                        betSlip: {
                            selections: getters.selections,
                            stake: getters.stake,
                            purchaseType: getters.purchaseType,
                            type: getters.type,
                            autoUpdateChanges: getters.autoUpdateChanges,
                            selectedSystems: getters.selectedSystems,
                        },
                        ticketType: TicketType.sport,
                    },
                    { root: true }
                );
            }),

        /**
         * Checks if selection has changed.
         * If selection has changed updates selection.  If selection didn't changed do nothing.
         */
        processSelectionChange(
            { commit, state, dispatch }: { commit: Commit, state: IBetSlipState, dispatch: Dispatch },
            payload: { selection: ISelection, newSelection: ISelection }
        ): void {

            // we always want fresh event data!
            commit(
                UPDATE_SELECTION_EVENT,
                {
                    selection: payload.selection,
                    newSelection: payload.newSelection
                }
            );
            commit(
                UPDATE_SELECTION,
                {
                    selection: payload.selection,
                    newSelection: payload.newSelection
                }
            );
            dispatch('calculatePayout');
            dispatch('validateBetSlip');
        },

        toggleSystemSelected(
            { commit, dispatch }: { commit: Commit, dispatch: Dispatch, rootGetters: any },
            system: System
        ) {
            commit(TOGGLE_SYSTEM_SELECTED, system);
            commit(TOGGLE_SELECTED_SYSTEM_MIN_NUMBER, system.getMinNumber());
            dispatch('calculatePayout');
        },

        validateBetSlip(
            { commit, getters, rootGetters }: { commit: Commit, getters: any, rootGetters: any },
            isSubmit = false
        ): void {
            commit(CLEAR_ERRORS);
            commit(CLEAR_SELECTION_ERRORS);

            const isInPlayAvailable = rootGetters['data/flags/isInPlayAvailable'] as boolean;

            const validationResult: IValidationResult = validationFactory()
                .validate(
                    {
                        selections: getters.selections,
                        purchaseType: getters.purchaseType,
                        stake: getters.stake,
                        stakePerCombination: getters.stakePerCombination,
                        autoUpdateChanges: getters.autoUpdateChanges,
                        selectedSystems: getters.selectedSystems,
                        type: getters.type,
                        payout: getters.payout,
                    },
                    isSubmit,
                    isInPlayAvailable,
                );
            if (validationResult.betSlipError) {
                commit(ADD_ERROR, validationResult.betSlipError);
            }

            if (isSubmit && !_isEmpty(validationResult.selectionErrors)) {
                commit(ADD_ERROR, BetSlipErrorFactory.createErrorsInSelections());
            }

            commit(SET_SELECTION_ERRORS, validationResult.selectionErrors);
        },

        recreateTicket: async (
            context: ActionContext<ITicketsStackState, IState>,
            ticket: Ticket
        ) => {
            await context.dispatch('ui/ticketsStack/setIsRecreatingTicket', true, { root: true });
            const currentRoute = context.rootGetters['navigation/route'].name;

            if (routeNames.sportOffer !== currentRoute
                && routeNames.landing !== currentRoute
            ) {
                context.dispatch(
                    'navigation/push',
                    {
                        name: routeNames.landing,
                    },
                    { root: true }
                );
            }
            context.dispatch('clear');
            context.dispatch('_clearUndoSelections');
            // changed the betslip purchase type online to offline
            context.dispatch('setPurchaseType', BetSlipPurchaseType.offline);
            context.dispatch('setStake', ticket.stake);
            if (ticket.system) {
                context.dispatch('setType', BetSlipType.system);
                context.commit(
                    SET_SELECTED_SYSTEM_MIN_NUMBER,
                    ticket.system.selected.reduce(
                        (a, item) => ({ ...a, [item]: true }),
                        {}
                    ),
                );
            } else {
                context.dispatch('setType', BetSlipType.simple);
            }
            const createSelections = async () => {
                for (const event of ticket.events) {
                    if (event.status === EventStatus.active) {
                        const eventId = parseInt(event.eventId.replace('S', ''), 10);
                        const oddId = parseInt(event.odd.oddId, 10);
                        const specialValue = event.odd.specialValue ? event.odd.specialValue : undefined;
                        const oddUuid = event.odd.oddUuid;
                        const sourceType = _get(event, 'odd.sourceType');

                        if (sourceType === OddSourceType.BET_BUILDER && event.odd) {
                            await context.dispatch('data/sportOffer/subscribeToBetBuilderOddChange', { matchId: eventId, oddUuid }, { root: true });
                        }

                        let createdEvent = context.rootGetters['data/sportOffer/eventsMap'][eventId];
                        let odd;
                        if (!createdEvent || !(odd = createdEvent.getOddById(oddId, specialValue))) {
                            const fetchedSelection = await Helpers.fetchSelection({
                                oddId,
                                eventId,
                                specialBetValue: specialValue,
                                type: SelectionType.sport,
                                sourceType,
                                oddUuid
                            });

                            if (fetchedSelection) {
                                fetchedSelection.isFixed = !!event.odd.isFix;
                                await context.dispatch('addSelection', { selection: fetchedSelection, runValidation: false, isRecreate: true });
                            }
                        } else {
                            const selection = SportSelection.createItem(createdEvent, odd, {
                                source: {
                                    type: sourceType,
                                },
                            });

                            selection.isFixed = !!event.odd.isFix;
                            await context.dispatch('addSelection', { selection, runValidation: false, isRecreate: true });
                        }
                    }
                }
            };
            try {
                await createSelections();
            } catch (error) {
                context.dispatch('ui/ticketsStack/setIsRecreatingTicket', false, { root: true });
                throw error;
            }

            context.dispatch('validateBetSlip', null);
            context.dispatch('calculatePayout');
            context.dispatch('ui/ticketsStack/setIsRecreatingTicket', false, { root: true });
        },

        async _clearUndoSelections(
            { commit, state }: { commit: Commit, state: IBetSlipState }
        ) {
            if (state.undoSelectionTimerId) {
                clearTimeout(state.undoSelectionTimerId);
            }

            commit(SET_UNDO_SELECTIONS_TIMER_ID, null);
            commit(SET_UNDO_SELECTIONS, null);
            commit(SET_UNDO_SYSTEMS, null);
        },

        /**
         * This method uses selections from persistance and adds selections to betsLip
         *
         */
        async _fetchSelection(
            { dispatch }: { commit: Commit, dispatch: Dispatch, getters: any },
            serializedSelection: ISerializedSelection
        ) {
            const selection = await BetSlipHelper.fetchSelection({
                oddId: serializedSelection.oddId,
                eventId: serializedSelection.eventId!,
                specialBetValue: serializedSelection.specialBetValue,
                type: serializedSelection.itemType,
                offerId: serializedSelection.offerId,
                sourceType: 0,
            });

            if (!selection) {
                return;
            }

            selection.isFixed = serializedSelection.isFixed;

            if (selection.getOddValue() === serializedSelection.oddValue
                || !selection.odd
                || !serializedSelection.oddValue) {
                dispatch('addSelection', { selection, runValidation: false });
            } else {
                // first we add persisted selection, one with the old odd value
                // because if odd changes values system must ask us do we wanna change it
                let persistedSelection;

                // TODO check why types don't align
                persistedSelection = SportSelection.createItem(selection.event, Odd.copy(selection.odd as Odd));
                persistedSelection.setOddValue(serializedSelection.oddValue);

                dispatch('addSelection', { selection: persistedSelection });
                dispatch(
                    'processSelectionChange',
                    {
                        selection: persistedSelection,
                        newSelection: selection
                    }
                );
            }
        },
    };
};
